"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSafeState = void 0;
var react_1 = require("react");
/**
 * Only updates the state, if the component is mounted
 *
 * This is useful to avoid this error: `Can't perform a React state update on an
 * unmounted component` _But don't forget to unbind any unused listeners anyway!_
 */
var useSafeState = function (initial) {
    var isMounted = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(function () { return function () {
        isMounted.current = false;
    }; }, []);
    var _a = __read((0, react_1.useState)(initial), 2), state = _a[0], setState = _a[1];
    var setStateIfMounted = function (result) {
        if (!isMounted.current) {
            return;
        }
        setState(result);
    };
    return [state, setStateIfMounted];
};
exports.useSafeState = useSafeState;
