export const utf8ToBase64 = (utf8: string): string => {
    try {
        return btoa(unescape(encodeURIComponent(utf8)));
    } catch (e) {
        return btoa(utf8);
    }
};

export const base64ToUtf8 = (base64: string): string => {
    try {
        return decodeURIComponent(escape(atob(base64)));
    } catch (e) {
        return atob(base64);
    }
};
