import devStore from "@mittwald/flow-lib/dist/store/dev";
import faker from "./faker";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import { MittwaldApi } from "../api/Mittwald";
import { mittwaldApiRequestMocking } from "../api/Mittwald.mocks";
import { mittwaldLegacyApiRequestMocking } from "../api/MittwaldLegacy.mocks";
import { base64ToUtf8 } from "../lib/utf8";
import varnishTemplate from "./varnishTemplate";

export const initVarnish = (): void => {
    let revisions: MittwaldApi.Components.Schemas.De_Mittwald_V1_Varnish_ConfigRevision[] = [
        {
            updated: DateTime.fromJSDate(faker.date.past()).toISO(),
            revision: 1,
            expire: DateTime.fromJSDate(faker.date.future()).toSeconds(),
            files: [
                {
                    meta: {
                        name: "foo",
                    },
                    refId: "first",
                },
            ],
        },
        {
            updated: DateTime.fromJSDate(faker.date.recent()).toISO(),
            revision: 2,
            expire: DateTime.fromJSDate(faker.date.future()).toSeconds(),
            files: [
                {
                    meta: {
                        name: "foo",
                    },
                    refId: "second",
                },
            ],
        },
    ];

    const templates: MittwaldApi.Components.Schemas.De_Mittwald_V1_Varnish_ConfigTemplate[] = [
        {
            softwareConfigTemplateId: "first",
            files: [
                {
                    refId: "first",
                },
            ],
            softwareTemplateId: "foo",
            isGlobal: false,
            note: "first template",
            projectId: "foo",
            updatedAt: DateTime.fromJSDate(faker.date.past()).toISO(),
        },
        {
            softwareConfigTemplateId: "second",
            files: [
                {
                    refId: "second",
                },
            ],
            softwareTemplateId: "foo",
            isGlobal: false,
            note: "second template",
            projectId: "foo",
            updatedAt: DateTime.fromJSDate(faker.date.recent()).toISO(),
        },
        {
            softwareConfigTemplateId: "global",
            files: [
                {
                    refId: "global",
                },
            ],
            softwareTemplateId: "foo",
            isGlobal: true,
            note: "global template",
        },
    ];

    revisions = revisions.sort((a, b) => DateTime.fromISO(b.updated).toSeconds() - DateTime.fromISO(a.updated).toSeconds());

    const domains = [faker.internet.domainName(), faker.internet.domainName()];

    const getSubdomain = (domain: string): string => `${faker.internet.domainWord()}.${domain}`;

    const subdomains = [getSubdomain(domains[0]), getSubdomain(domains[1]), getSubdomain(domains[1])];

    mittwaldLegacyApiRequestMocking.mockListDomainsByAccount(
        {
            path: { accountIdentifier: "*" },
        },
        {
            status: 200,
            content: [
                {
                    fullName: domains[0],
                    registrarStatus: "LOCK",
                    target: "/wordpress/",
                    uid: 559371,
                },
                {
                    fullName: subdomains[0],
                    target: "/wordpress/",
                    uid: 6055097,
                },
                {
                    fullName: subdomains[1],
                    target: "/wordpress/",
                    uid: 6055100,
                },
                {
                    fullName: subdomains[2],
                    target: "/wordpress/",
                    uid: 6234100,
                },
            ],
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareGetById(
        {
            path: {
                softwareId: "*",
            },
        },
        () => {
            const varnishDemoDataEnabled = devStore.featureEnabled("varnishDemodata");

            return {
                status: 200,
                mediaType: "application/json",
                content: {
                    id: "foo",

                    revisions: varnishDemoDataEnabled ? revisions : [],
                },
            };
        },
    );

    mittwaldApiRequestMocking.mockFileGetFile(
        {
            path: {
                fileId: "*",
            },
        },
        (request) => ({
            content: Buffer.from(varnishTemplate(request.path.fileId)).toString("base64"),
            status: 200,
            mediaType: "base64",
        }),
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareConfigtemplateGetList(
        {
            path: {
                projectId: "*",
            },
        },
        {
            status: 200,
            content: { templates },
            headers: {},
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareConfigtemplateListGlobals(
        {
            path: {
                softwareTemplateId: "*",
            },
        },
        {
            status: 200,
            content: [],
            headers: {},
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareGetById(
        {
            path: {
                softwareId: "*",
            },
        },
        () => {
            const varnishDemoDataEnabled = devStore.featureEnabled("varnishDemodata");

            return {
                status: 200,
                content: {
                    config: {
                        latestConfigRevision: varnishDemoDataEnabled ? 1 : 0,
                        revisions: varnishDemoDataEnabled ? revisions : [],
                    },
                    softwareTemplateId: "foo",
                    projectId: "foo",
                    settings: varnishDemoDataEnabled
                        ? {
                              domains: `${subdomains[0]},${domains[0]}`,
                              active: "true",
                          }
                        : {},
                    softwareId: "foo",
                    softwareVersion: "1.0.0",
                    updatedAt: DateTime.fromJSDate(faker.date.past()).toSeconds(),
                },
            };
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareConfigtemplateCreate(
        {
            path: {
                projectId: "*",
            },
        },
        (request) => {
            const template = request.requestBody;
            templates.push({
                softwareConfigTemplateId: template.softwareConfigTemplateId || "",
                files: [
                    {
                        refId: template.note || template.softwareConfigTemplateId || "",
                    },
                ],
                softwareTemplateId: template.softwareTemplateId || "foo",
                isGlobal: false,
                note: template.note,
                projectId: request.path.projectId,
                updatedAt: DateTime.local().toISO(),
            });

            return {
                status: 200,
                content: {},
            };
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceValidate({}, (request) => {
        const isSuccess = base64ToUtf8(request.requestBody.files[0].content).includes("foo");
        if (isSuccess) {
            return {
                status: 200,
                content: {
                    success: true,
                },
            };
        }

        return {
            status: 200,
            content: {
                success: false,
                stages: [
                    {
                        name: "NoFooError",
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        errorType: "NoFooError",
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        errorMessage: "You got no 'foo' in your vcl",
                    },
                ],
            },
        };
    });

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareConfigDeclare(
        {
            path: {
                softwareId: "*",
            },
        },
        {
            status: 200,
            content: {
                files: [
                    {
                        id: uuid(),
                    },
                ],
                revision: 1,
            },
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareSettingsDeclare(
        {
            path: {
                softwareId: "*",
            },
        },
        {
            status: 200,
            content: {},
        },
    );

    mittwaldApiRequestMocking.mockVarnishServiceSoftwareLogs(
        {
            path: {
                softwareId: "*",
            },
        },
        {
            status: 200,
            content: {},
        },
    );
};
