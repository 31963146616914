"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointsContext = exports.BreakpointsProvider = exports.breakpointsContext = void 0;
var react_1 = require("react");
exports.breakpointsContext = (0, react_1.createContext)({
    contextClassName: "",
    breakpoint: 0,
    allBreakpoints: [],
    index: -1,
});
exports.BreakpointsProvider = exports.breakpointsContext.Provider;
var useBreakpointsContext = function () {
    return (0, react_1.useContext)(exports.breakpointsContext);
};
exports.useBreakpointsContext = useBreakpointsContext;
