"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildValidObjectPath = void 0;
var config_1 = require("./config");
var buildValidObjectPath = function () {
    var parts = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        parts[_i] = arguments[_i];
    }
    return parts.join(config_1.validDelimiter);
};
exports.buildValidObjectPath = buildValidObjectPath;
