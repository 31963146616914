"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreadcrumbContextProvider = exports.breadcrumbContext = void 0;
var Breadcrumb_1 = require("./Breadcrumb");
var react_1 = require("react");
var Page_1 = require("./Page");
exports.breadcrumbContext = (0, react_1.createContext)({
    breadcrumb: Breadcrumb_1.Breadcrumb.fromPages([new Page_1.Page()]),
});
exports.BreadcrumbContextProvider = exports.breadcrumbContext.Provider;
