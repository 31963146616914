"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateActionForLinkId = void 0;
var useBreadcrumbWithLinkId_1 = require("@mittwald/flow-lib/dist/hooks/useBreadcrumbWithLinkId");
var createActionFromBreadcrumb_1 = require("./createActionFromBreadcrumb");
var useCreateActionForLinkId = function (linkId) {
    var linkBreadcrumb = (0, useBreadcrumbWithLinkId_1.useBreadcrumbWithLinkId)(linkId);
    return (0, createActionFromBreadcrumb_1.createActionFromBreadcrumb)(linkBreadcrumb);
};
exports.useCreateActionForLinkId = useCreateActionForLinkId;
