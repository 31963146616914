"use strict";
/**
 * This interface contains information about the form error. path specifies the
 * concrete property path inside the form schema e.g. if the Form Schema
 * Interface is: `javascript export interface FormSchema { foo: { bar: string }
 * baz: string; } ` ..., then bar would have the path `foo.bar` and baz would
 * have `baz` }
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapServiceValidationErrors = exports.isServiceValidationError = void 0;
var isServiceValidationError = function (error) {
    return (typeof error === "object" &&
        "type" in error &&
        typeof error.type === "string" &&
        error.type === "ValidationError" &&
        "validationErrors" in error &&
        Array.isArray(error.validationErrors) &&
        error.validationErrors.length > 0);
};
exports.isServiceValidationError = isServiceValidationError;
var extractPropertyPath = function (error) {
    var _a;
    var path = "";
    if (error.path.startsWith(".")) {
        path += error.path.substring(1);
    }
    else {
        path += error.path;
    }
    if (((_a = error.context) === null || _a === void 0 ? void 0 : _a.missingProperty) && error.type === "required") {
        if (path !== "") {
            path += ".";
        }
        path += error.context.missingProperty;
    }
    return path;
};
var mapServiceValidationErrors = function (serviceValidationError) {
    return serviceValidationError.validationErrors.map(function (error) {
        var path = extractPropertyPath(error);
        return {
            path: path,
            message: error.message,
            type: error.type,
            params: error.context,
        };
    });
};
exports.mapServiceValidationErrors = mapServiceValidationErrors;
