import { resetAllRequestMocks } from "@mittwald/api-client/dist/http/mockRequestFactory";
import fetchMock from "fetch-mock";
import { initVarnish } from "./varnish";

fetchMock.config.fallbackToNetwork = true;

export const initDemoDataMocking: () => () => void = () => {
    initVarnish();

    return () => {
        resetAllRequestMocks();
    };
};
