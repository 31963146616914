"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntlProvider = void 0;
var react_1 = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var debug_1 = require("../debug");
var useCurrentLanguage_1 = require("../hooks/useCurrentLanguage");
var debug = debug_1.debug.extend("IntlProvider");
var cache = (0, react_intl_1.createIntlCache)();
var IntlProvider = function (props) {
    var languageFromStore = (0, useCurrentLanguage_1.useCurrentLanguage)().language;
    var defaultMessages = props.default, importLanguage = props.importLanguage, _a = props.setupLanguage, setupLanguage = _a === void 0 ? function () { } : _a;
    setupLanguage(languageFromStore);
    var _b = __read((0, react_1.useState)((0, react_intl_1.createIntl)({
        locale: languageFromStore,
        defaultLocale: languageFromStore,
        messages: defaultMessages,
    }, cache)), 2), intl = _b[0], setIntl = _b[1];
    (0, react_1.useEffect)(function () {
        if (intl.locale === languageFromStore) {
            debug("language already set to %s", languageFromStore);
        }
        setupLanguage(languageFromStore);
        if (!importLanguage) {
            debug("importLanguage() callback not defined. Skipping import of language file.");
            return;
        }
        function setLanguage(_a) {
            var messages = _a.default;
            debug("setting %d loaded messages", Object.keys(messages !== null && messages !== void 0 ? messages : {}).length);
            setIntl((0, react_intl_1.createIntl)({
                messages: messages,
                locale: languageFromStore,
                defaultLocale: intl.defaultLocale,
            }, cache));
        }
        debug("loading language %s", languageFromStore);
        importLanguage(languageFromStore).then(setLanguage).catch(console.error);
    }, [languageFromStore]);
    return react_1.default.createElement(react_intl_1.RawIntlProvider, { value: intl }, props.children);
};
exports.IntlProvider = IntlProvider;
