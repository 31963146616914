"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalCurtainStyle = exports.curtainVisibleClassNamePrefix = void 0;
var styled_components_1 = require("styled-components");
exports.curtainVisibleClassNamePrefix = "curtain-visible-";
exports.GlobalCurtainStyle = (0, styled_components_1.createGlobalStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html[class*=\"", "\"] {\n    &, body {\n      overflow: hidden;\n      box-sizing: border-box;\n      /**\n       * Wait a minute, what is that calc() doing here?!\n       * Let me try to explain. If the iOS Safari footer is hidden when the curtain\n       * opens, iOS will reveal the footer if the user makes a vertical pan \n       * gesture, the 1px prevents this from happening. I don\u2019t know. \uD83E\uDD37\u200D\u2642\uFE0F\n       */\n      height: calc(var(--window-inner-height) - 1px);\n    }\n  }\n"], ["\n  html[class*=\"", "\"] {\n    &, body {\n      overflow: hidden;\n      box-sizing: border-box;\n      /**\n       * Wait a minute, what is that calc() doing here?!\n       * Let me try to explain. If the iOS Safari footer is hidden when the curtain\n       * opens, iOS will reveal the footer if the user makes a vertical pan \n       * gesture, the 1px prevents this from happening. I don\u2019t know. \uD83E\uDD37\u200D\u2642\uFE0F\n       */\n      height: calc(var(--window-inner-height) - 1px);\n    }\n  }\n"])), exports.curtainVisibleClassNamePrefix);
var templateObject_1;
