"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compactModeFooterDeadzone = void 0;
/**
 * IOS does reserve some space at the bottom of the window to disable the
 * compact UI when clicking into this area (deadzone). This conflicts with fixed
 * buttons placed in this area, so you have to click, scroll-up and click again.
 * To avoid this nasty behaviour provide this suggested space to your fixed buttons.
 */
exports.compactModeFooterDeadzone = 80;
