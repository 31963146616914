"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCurtainOpacity = exports.updateCssVar = exports.body = exports.html = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
/**
 * Prevent scrolling the page on iOS Safari 15 see
 * https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
 */
exports.html = document.documentElement;
exports.body = document.body;
var updateCssVar = function () {
    var sizeInPx = "".concat(window.innerHeight, "px");
    exports.html.style.setProperty("--window-inner-height", sizeInPx);
};
exports.updateCssVar = updateCssVar;
exports.defaultCurtainOpacity = variables_1.opacity.curtain.default;
