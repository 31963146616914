import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import { config } from "../../config";
import { MittwaldLegacyApiClient } from "../MittwaldLegacy";

const httpClient = new KyClient({
    ky: {
        prefixUrl: config.legacyApiUri,
    },
});

export const mittwaldLegacyApiClient = new MittwaldLegacyApiClient(httpClient);
MittwaldLegacyApiClient.setInstance(mittwaldLegacyApiClient);

export default mittwaldLegacyApiClient;
