"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestError = void 0;
class RequestError extends Error {
    constructor(path, method, cause) {
        const causeMessage = cause instanceof Error ? `: ${cause.message}` : "undefined";
        super(`${method.toUpperCase()} ${path} failed${causeMessage}`);
        this.name = "RequestError";
        this.path = path;
        this.method = method.toUpperCase();
        this.cause = cause;
        Object.setPrototypeOf(this, RequestError.prototype);
    }
}
exports.RequestError = RequestError;
exports.default = RequestError;
