"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnChange = void 0;
var react_1 = require("react");
/** Like `useEffect`, but without the "initial call" */
var useOnChange = function (call, dependencies) {
    var effectFirstRun = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(function () {
        if (effectFirstRun.current) {
            effectFirstRun.current = false;
            return;
        }
        call();
    }, dependencies);
};
exports.useOnChange = useOnChange;
