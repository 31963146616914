"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var polished_1 = require("polished");
var react_1 = require("react");
/** Check the element or up to 10 parent elements for the computed background color */
var useComputedBackgroundColor = function () {
    var _a = __read((0, react_1.useState)("computing"), 2), color = _a[0], setColor = _a[1];
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        setColor("computing");
        var elemToCheck = ref.current;
        var backgroundColorFound = false;
        // check up to 3 parents for a computed background color
        for (var i = 0; i < 10 && elemToCheck; i++) {
            var backgroundColor = window.getComputedStyle(elemToCheck).backgroundColor;
            if (!backgroundColor) {
                elemToCheck = elemToCheck.parentElement;
                continue;
            }
            var rgba = (0, polished_1.parseToRgb)(backgroundColor);
            // alpha == 0 ~> no background color set
            if ("alpha" in rgba && rgba.alpha === 0) {
                // continue with next parent
                elemToCheck = elemToCheck.parentElement;
                continue;
            }
            setColor(rgba);
            backgroundColorFound = true;
            break;
        }
        if (!backgroundColorFound) {
            setColor(undefined);
        }
    }, [ref.current]);
    return {
        backgroundColor: color,
        ref: ref,
    };
};
exports.default = useComputedBackgroundColor;
