"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonPropsType = void 0;
var statusType_1 = require("../../lib/statusType");
var outerLayout_1 = require("../../mixins/outerLayout");
exports.buttonPropsType = statusType_1.userInteractionStatusPropsType
    .extend(outerLayout_1.outerLayoutMixinPropsType)
    .complete({
    className: true,
    _dangerouslyOverrideIconSize: true,
    _internal: true,
    autoFocus: true,
    block: true,
    busy: true,
    children: true,
    icon: true,
    text: true,
    action: true,
    iconPosition: true,
    disabled: true,
});
