"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requireItemAtIndex = void 0;
var requireItemAtIndex = function (items, index, errorMessage) {
    var item = items[index];
    if (item === undefined) {
        throw new Error(errorMessage !== null && errorMessage !== void 0 ? errorMessage : "Could not get required item at index ".concat(index));
    }
    return item;
};
exports.requireItemAtIndex = requireItemAtIndex;
exports.default = exports.requireItemAtIndex;
