"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userFeedbackStore = exports.model = void 0;
var luxon_1 = require("luxon");
var mobx_state_tree_1 = require("mobx-state-tree");
var triggerTypeModel = mobx_state_tree_1.types.enumeration([
    "firstTime",
    "repeatedly",
    "unknown",
]);
var feedbacksModel = mobx_state_tree_1.types.model({
    key: mobx_state_tree_1.types.string,
    type: triggerTypeModel,
    triggeredAt: mobx_state_tree_1.types.array(mobx_state_tree_1.types.number),
    submittedAt: mobx_state_tree_1.types.maybe(mobx_state_tree_1.types.number),
    mutedAt: mobx_state_tree_1.types.maybe(mobx_state_tree_1.types.number),
});
/**
 * This duration must be elapsed between triggering, to indicate that a feature
 * is used repeatedly over a certain span of time.
 */
var minimumRepeatedlyTimeGap = luxon_1.Duration.fromObject({
    days: 1,
}).toMillis();
/** This is the minimum amount triggering, to indicate that a feature is used repeatedly. */
var minimumRepeatedlyTriggers = 3;
var isTriggerDue = function (trigger) {
    var e_1, _a;
    if (trigger.submittedAt || trigger.mutedAt || trigger.type === "unknown") {
        return false;
    }
    if (trigger.type === "firstTime") {
        return true;
    }
    // Check "repeatedly conditions"
    if (trigger.triggeredAt.length < minimumRepeatedlyTriggers) {
        return false;
    }
    var prevTimestamp = Number.MAX_VALUE;
    try {
        for (var _b = __values(trigger.triggeredAt), _c = _b.next(); !_c.done; _c = _b.next()) {
            var timestamp = _c.value;
            if (timestamp - prevTimestamp > minimumRepeatedlyTimeGap) {
                return true;
            }
            prevTimestamp = timestamp;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
exports.model = mobx_state_tree_1.types
    .model({
    feedbacks: mobx_state_tree_1.types.map(feedbacksModel),
})
    .actions(function (self) { return ({
    register: function (key, type) {
        var _a;
        var timestamp = Date.now();
        var trigger = (_a = self.feedbacks.get(key)) !== null && _a !== void 0 ? _a : {
            key: key,
            type: type,
            triggeredAt: [],
            submittedAt: undefined,
            mutedAt: undefined,
        };
        trigger.triggeredAt.push(timestamp);
        // avoids uncontrolled growing
        trigger.triggeredAt = trigger.triggeredAt.slice(-10);
        trigger.type = type;
        self.feedbacks.set(key, trigger);
    },
    clear: function () {
        self.feedbacks.clear();
    },
    setSubmitted: function (key) {
        var trigger = self.feedbacks.get(key);
        var now = Date.now();
        if (trigger) {
            trigger.submittedAt = now;
        }
        else {
            self.feedbacks.set(key, {
                key: key,
                type: "unknown",
                submittedAt: now,
            });
        }
    },
    setMuted: function (key) {
        var trigger = self.feedbacks.get(key);
        if (trigger) {
            trigger.mutedAt = Date.now();
        }
    },
    onLoad: function () { },
}); })
    .views(function (self) {
    var dueFeedbacks = function () {
        return Array.from(self.feedbacks.values()).filter(isTriggerDue);
    };
    return {
        dueFeedbacks: dueFeedbacks,
        lastDueFeedback: function () { return dueFeedbacks()[0]; },
    };
});
exports.userFeedbackStore = exports.model.create({});
exports.default = exports.userFeedbackStore;
