"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFunctionalModalContextKey = exports.FunctionalModalContextProvider = exports.functionalModalContext = void 0;
var react_1 = require("react");
exports.functionalModalContext = (0, react_1.createContext)({});
exports.FunctionalModalContextProvider = exports.functionalModalContext.Provider;
var useFunctionalModalContextKey = function () {
    return (0, react_1.useContext)(exports.functionalModalContext).key;
};
exports.useFunctionalModalContextKey = useFunctionalModalContextKey;
