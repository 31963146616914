"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tooltipSingletonsStore = exports.tooltipSingletonsModel = void 0;
var mobx_state_tree_1 = require("mobx-state-tree");
exports.tooltipSingletonsModel = mobx_state_tree_1.types
    .model({
    singletons: mobx_state_tree_1.types.map(mobx_state_tree_1.types.frozen()),
})
    .actions(function (self) { return ({
    register: function (key, target) {
        self.singletons.set(key, target);
    },
}); });
exports.tooltipSingletonsStore = exports.tooltipSingletonsModel.create({
    singletons: {},
});
exports.default = exports.tooltipSingletonsStore;
