"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconPropsType = void 0;
var icon_1 = require("../../mixins/icon");
var outerLayout_1 = require("../../mixins/outerLayout");
var padding_1 = require("../../mixins/padding");
var textColor_1 = require("../../mixins/textColor");
exports.iconPropsType = padding_1.paddingMixinPropsType
    .extend(outerLayout_1.outerLayoutMixinPropsType)
    .extend(textColor_1.textColorMixinPropsType)
    .extend(icon_1.iconMixinPropsType)
    .complete({
    icon: true,
    className: true,
    disabled: true,
    fixedWidth: true,
    altText: true,
    action: true,
    spin: true,
});
