"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreadcrumb = void 0;
var useBreadcrumbContext_1 = require("./useBreadcrumbContext");
var useBreadcrumb = function (matcher) {
    var _a;
    var breadcrumb = (0, useBreadcrumbContext_1.useBreadcrumbContext)().breadcrumb;
    if (matcher) {
        return (_a = breadcrumb.findParentBreadcrumb(matcher)) !== null && _a !== void 0 ? _a : breadcrumb;
    }
    return breadcrumb;
};
exports.useBreadcrumb = useBreadcrumb;
