"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnexpectedResponseError = void 0;
class UnexpectedResponseError extends Error {
    constructor(response) {
        var _a, _b, _c, _d, _e, _f, _g;
        super();
        this.name = "UnexpectedResponseError";
        Object.setPrototypeOf(this, UnexpectedResponseError.prototype);
        const { url, status: statusCode, operation: apiOperation } = response;
        this.operationId = apiOperation === null || apiOperation === void 0 ? void 0 : apiOperation.operationId;
        this.path = (_a = apiOperation === null || apiOperation === void 0 ? void 0 : apiOperation.path) !== null && _a !== void 0 ? _a : url;
        this.httpMethod = apiOperation === null || apiOperation === void 0 ? void 0 : apiOperation.method;
        this.statusCode = statusCode;
        this.content = response.content;
        this.message = `${(_c = (_b = this.httpMethod) === null || _b === void 0 ? void 0 : _b.toUpperCase()) !== null && _c !== void 0 ? _c : "UNKNOWN"} ${(_d = this.path) !== null && _d !== void 0 ? _d : "unknown"} [${statusCode !== null && statusCode !== void 0 ? statusCode : "0"}`;
        if (this.content && typeof this.content === "object") {
            const responseErrorMessage = (_f = (_e = this.content.message) !== null && _e !== void 0 ? _e : this.content.error_description) !== null && _f !== void 0 ? _f : this.content.error;
            if (typeof responseErrorMessage === "string") {
                this.errorMessage = responseErrorMessage;
                this.message += `: ${responseErrorMessage}`;
            }
            const responseTraceId = (_g = this.content.params) === null || _g === void 0 ? void 0 : _g.traceId;
            if (typeof responseTraceId === "string") {
                this.traceId = responseTraceId;
            }
            const responseErrorType = this.content.type;
            if (typeof responseErrorType === "string") {
                this.errorType = responseErrorType;
            }
        }
    }
}
exports.UnexpectedResponseError = UnexpectedResponseError;
exports.default = UnexpectedResponseError;
