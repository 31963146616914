"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimer = void 0;
// [timerIsRunning, startTimer]
var react_1 = require("react");
var useTimer = function (timeoutMs) {
    var _a = __read((0, react_1.useState)(false), 2), timerIsRunning = _a[0], setTimerIsRunning = _a[1];
    var startTimer = function () {
        setTimerIsRunning(true);
    };
    (0, react_1.useEffect)(function () {
        if (timerIsRunning) {
            var timeout_1 = setTimeout(function () {
                setTimerIsRunning(false);
                return function () {
                    clearTimeout(timeout_1);
                };
            }, timeoutMs);
        }
    }, [timerIsRunning, setTimerIsRunning]);
    return [timerIsRunning, startTimer];
};
exports.useTimer = useTimer;
