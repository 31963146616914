"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVisibilityController = void 0;
var react_1 = require("react");
var useKeyListener_1 = require("./useKeyListener/useKeyListener");
/** Walks up the DOM tree until a mounted parent is found and returns it */
var getMountedParent = function (node) {
    if (node === null) {
        return null;
    }
    // is mounted?
    if (document.contains(node)) {
        return node;
    }
    return getMountedParent(node.parentNode);
};
/**
 * `useVisibilityController()` creates a `VisibilityController` which lets you
 * control the visibility state of component from inside and outside the
 * component with a convenient API.
 *
 * To control the visibility of your custom component just support a "controller
 * prop" (of type `VisibilityController`) and use its API inside your component.
 * By providing the controller as prop you get the advantage of controlling the
 * state also from outside the component.
 *
 * By default the controlled component will be hidden, if the user clicks
 * outside the component. You can disable the behaviour via the options
 * parameter. _Notice:_ To make this work, put the `ref` from the
 * `VisibilityController` into you controlled component (see example in Storybook).
 */
var useVisibilityController = function (defaultVisibility, options) {
    var _a, _b;
    if (defaultVisibility === void 0) { defaultVisibility = false; }
    if (options === void 0) { options = {}; }
    var _c = options.onHide, onHide = _c === void 0 ? function () { } : _c, _d = options.onShow, onShow = _d === void 0 ? function () { } : _d, _e = options.onChanged, onChanged = _e === void 0 ? function () { } : _e;
    /**
     * This ref is used to track if the mouse click event that would disabled the
     * visibility (hideOnClickOut) is not the same mouse event which enabled the
     * visibility.
     */
    var mouseDownAfterIsVisible = (0, react_1.useRef)(false);
    /** Setup the hideOnClickOut feature. */
    var clickOutRefs = (0, react_1.useRef)();
    var clickOutRefHandler = function (ref) {
        clickOutRefs.current = ref;
    };
    /**
     * Put it in state, because `hideOnClick` and `hideOnEsc` can be changed by
     * their respective update methods located in the Controller
     */
    var _f = __read((0, react_1.useState)((_a = options.hideOnClickOut) !== null && _a !== void 0 ? _a : false), 2), hideOnClickOut = _f[0], setHideOnClickOut = _f[1];
    var _g = __read((0, react_1.useState)((_b = options.hideOnEsc) !== null && _b !== void 0 ? _b : false), 2), hideOnEsc = _g[0], setHideOnEsc = _g[1];
    var _h = __read((0, react_1.useState)(defaultVisibility), 2), visible = _h[0], setVisible = _h[1];
    var _j = __read((0, react_1.useState)("initial"), 2), animationState = _j[0], setAnimationState = _j[1];
    var hide = function () {
        setVisible(false);
    };
    var show = function () {
        setVisible(true);
    };
    var toggle = function () {
        setVisible(function (v) { return !v; });
    };
    // it's "pristine" until visibility has changed once
    var pristine = (0, react_1.useRef)(true);
    /** If visible state has changed, call the callbacks */
    (0, react_1.useEffect)(function () {
        if (visible) {
            setAnimationState("initial");
        }
        var isDirty = visible !== defaultVisibility;
        if (isDirty) {
            pristine.current = false;
        }
        if (pristine.current) {
            // skip calling the callbacks if "pristine"
            return;
        }
        if (visible) {
            onShow();
        }
        else {
            onHide();
        }
        onChanged(visible);
        return function () {
            pristine.current = true;
        };
    }, [visible]);
    var handleDocumentClick = function (e) {
        if (!clickOutRefs.current ||
            !e.target ||
            !mouseDownAfterIsVisible.current) {
            return;
        }
        var target = getMountedParent(e.target);
        if (target && !clickOutRefs.current.contains(target)) {
            hide();
        }
    };
    var handleDocumentMouseDown = function () {
        mouseDownAfterIsVisible.current = true;
    };
    (0, react_1.useEffect)(function () {
        if (!visible) {
            mouseDownAfterIsVisible.current = false;
            return;
        }
        if (!hideOnClickOut) {
            return;
        }
        /**
         * Keep this a click event (even if click includes mousedown), to be able to
         * stop propagation on other click events.
         */
        document.addEventListener("click", handleDocumentClick);
        document.addEventListener("mousedown", handleDocumentMouseDown);
        return function () {
            document.removeEventListener("click", handleDocumentClick);
            document.removeEventListener("mousedown", handleDocumentMouseDown);
        };
    }, [visible, hideOnClickOut]);
    var setVisibility = function (visible) {
        setVisible(visible);
    };
    // Setup hide on Escape
    var handleEscPressed = function () {
        if (hideOnEsc) {
            hide();
        }
    };
    (0, useKeyListener_1.useKeyListener)("Escape", handleEscPressed, "keyup");
    return (0, react_1.useMemo)(function () { return ({
        hide: hide,
        show: show,
        toggle: toggle,
        setVisibility: setVisibility,
        visible: visible,
        hidden: !visible,
        setHideOnClickOut: setHideOnClickOut,
        setHideOnEsc: setHideOnEsc,
        ref: clickOutRefHandler,
        isPristine: pristine.current,
        exitAnimationState: animationState,
        updateAnimationState: setAnimationState,
    }); }, [visible, animationState]);
};
exports.useVisibilityController = useVisibilityController;
