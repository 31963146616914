"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showModal = exports.functionalModalsStore = void 0;
var mobx_state_tree_1 = require("mobx-state-tree");
exports.functionalModalsStore = mobx_state_tree_1.types
    .model({
    modals: mobx_state_tree_1.types.map(mobx_state_tree_1.types.frozen()),
})
    .actions(function (self) { return ({
    showModal: function (name, element) {
        self.modals.set(name, element);
    },
    removeModal: function (name) {
        self.modals.delete(name);
    },
    clearModals: function () {
        self.modals.clear();
    },
}); })
    .create();
/** Can show Modals that do support FunctionModalProps */
var showModal = function (name, modal) { return exports.functionalModalsStore.showModal(name, modal); };
exports.showModal = showModal;
