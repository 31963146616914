import devStore from "@mittwald/flow-lib/dist/store/dev";
import { initDemoDataMocking } from "../demo/init";
import { autorunWithCleanup, CleanupFn } from "../lib/autorunWithCleanup";

const startDemoDataMocking = (cleanup: CleanupFn[]): void => {
    const disableDemoDataMocking = initDemoDataMocking();
    cleanup.push(disableDemoDataMocking);
};

export const autorunAppInitialization = (): void => {
    autorunWithCleanup(() => {
        const inDemoMode = devStore.featureEnabled("varnishDemodata");

        const cleanupFunctions: CleanupFn[] = [];

        if (inDemoMode) {
            startDemoDataMocking(cleanupFunctions);
        }

        return cleanupFunctions;
    });
};
