"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreadcrumbProvider = void 0;
var react_1 = __importStar(require("react"));
var BreadcrumbContext_1 = require("./BreadcrumbContext");
var BreadcrumbProvider = function (props) {
    var breadcrumbFromProps = props.breadcrumb, _a = props.store, store = _a === void 0 ? false : _a;
    var ref = (0, react_1.useRef)();
    var breadcrumbChangedLevel = ref.current && ref.current.length !== breadcrumbFromProps.length;
    var breadcrumb = breadcrumbChangedLevel && store && ref.current
        ? ref.current
        : breadcrumbFromProps;
    if (store) {
        ref.current = breadcrumb;
    }
    return (react_1.default.createElement(BreadcrumbContext_1.BreadcrumbContextProvider, { value: {
            breadcrumb: breadcrumb,
        } }, props.children));
};
exports.BreadcrumbProvider = BreadcrumbProvider;
