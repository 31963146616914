"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncVisibility = void 0;
var react_1 = require("react");
var useSyncVisibility = function (controller, visible) {
    (0, react_1.useEffect)(function () {
        controller.setVisibility(visible);
    }, [visible]);
};
exports.useSyncVisibility = useSyncVisibility;
exports.default = exports.useSyncVisibility;
