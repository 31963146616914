"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FoldDownAnimation = void 0;
var hasChildren_1 = require("@mittwald/flow-lib/dist/lib/hasChildren");
var framer_motion_1 = require("framer-motion");
var react_1 = __importStar(require("react"));
var config_1 = require("./config");
var HeightAnimation = function (props) {
    var _a = __read((0, react_1.useState)(false), 2), isAnimating = _a[0], setIsAnimating = _a[1];
    var isPresent = (0, framer_motion_1.useIsPresent)();
    var onAnimationComplete = props.onAnimationComplete, onAnimationStart = props.onAnimationStart, children = props.children, rest = __rest(props, ["onAnimationComplete", "onAnimationStart", "children"]);
    var disablePointerEvents = isAnimating || !isPresent;
    var handleAnimationComplete = function (def) {
        setIsAnimating(false);
        onAnimationComplete && onAnimationComplete(def);
    };
    var handleAnimationStart = function (def) {
        setIsAnimating(true);
        onAnimationStart && onAnimationStart(def);
    };
    return (react_1.default.createElement(config_1.HeightAnimationContainer, __assign({ animate: ["enter1", "enter2"], exit: ["exit1", "exit2"], initial: ["init1", "init2"], key: "heightAnimationWrapper", style: {
            pointerEvents: disablePointerEvents ? "none" : "auto",
            position: "relative",
        }, 
        /**
         * Setting `pointer-events: none` during animation prevents unintended
         * hover-state changes, when the animated container should move "under"
         * the pointer.
         */
        variants: config_1.animations.height, onAnimationComplete: handleAnimationComplete, onAnimationStart: handleAnimationStart }, rest), children));
};
/** Animates the children with a "fold-down" effect */
var FoldDownAnimation = function (props) {
    var _dangerouslySetZIndex = props._dangerouslySetZIndex, onAnimationStart = props.onAnimationStart, onAnimationComplete = props.onAnimationComplete, _a = props.initial, initial = _a === void 0 ? true : _a, children = props.children, rest = __rest(props, ["_dangerouslySetZIndex", "onAnimationStart", "onAnimationComplete", "initial", "children"]);
    var showContent = (0, hasChildren_1.hasChildren)(children);
    var content = (react_1.default.createElement(HeightAnimation, { _dangerouslySetZIndex: _dangerouslySetZIndex, onAnimationComplete: onAnimationComplete, onAnimationStart: onAnimationStart },
        react_1.default.createElement(config_1.MainAnimationContainer, __assign({}, rest, { key: "mainAnimationWrapper", variants: config_1.animations.main }), children)));
    var AnimatePresenceWithChildren = framer_motion_1.AnimatePresence;
    return (react_1.default.createElement(AnimatePresenceWithChildren, { initial: initial }, showContent && content));
};
exports.FoldDownAnimation = FoldDownAnimation;
exports.default = exports.FoldDownAnimation;
