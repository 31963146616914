"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePickBreakpointProps = void 0;
var useGetBreakpointPropValue_1 = require("./useGetBreakpointPropValue");
var usePickBreakpointProps = function (props) {
    var propNames = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        propNames[_i - 1] = arguments[_i];
    }
    var getValue = (0, useGetBreakpointPropValue_1.useGetBreakpointPropValue)();
    var pickedResult = Object.fromEntries(propNames.map(function (prop) { return [prop, getValue(props[prop])]; }));
    var restPropNames = Object.keys(props).filter(function (p) { return !propNames.includes(p); });
    var pickedRestResult = Object.fromEntries(restPropNames.map(function (prop) { return [prop, props[prop]]; }));
    return [pickedResult, pickedRestResult];
};
exports.usePickBreakpointProps = usePickBreakpointProps;
exports.default = exports.usePickBreakpointProps;
