import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import StandaloneAppRenderer from "@mittwald/flow-renderer/dist/components/StandaloneAppRenderer/StandaloneAppRenderer";
import { lazy } from "react";
import { faCloudUpload } from "@fortawesome/pro-regular-svg-icons/faCloudUpload";

export const pageTree = PageTree.build(StandaloneAppRenderer, {
    "/": {
        views: {
            main: lazy(() => import("./index")),
        },
        props: {
            pageInset: "l",
        },
        linkId: "root",
    },
    "/templates": {
        views: {
            main: lazy(() => import("./templates")),
        },
        linkId: "varnish-templates",
        isOverlay: true,
        icon: faCloudUpload,
    },
    "/history": {
        views: {
            main: lazy(() => import("./history")),
        },
        linkId: "varnish-history",
        isOverlay: true,
        icon: faCloudUpload,
    },
});
