"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.opacity = exports.duration = exports.misc = exports.font = exports.spacing = exports.size = exports.color = exports.asset = void 0;
exports.asset = {
    font: {
        base: {
            Roboto: {
                ttf: "tokens/assets/fonts/Roboto-Regular.ttf",
            },
            RobotoMono: {
                ttf: "tokens/assets/fonts/RobotoMono-Regular.ttf",
            },
            HindSiliguri: {
                ttf: {
                    light: "tokens/assets/fonts/hind-siliguri-v6-latin-300.ttf",
                    regular: "tokens/assets/fonts/hind-siliguri-v6-latin-regular.ttf",
                    bold: "tokens/assets/fonts/hind-siliguri-v6-latin-600.ttf",
                },
            },
            Frutiger: {
                woff: {
                    light: "tokens/assets/fonts/frutiger-300.woff",
                    regular: "tokens/assets/fonts/frutiger-500.woff",
                    bold: "tokens/assets/fonts/frutiger-700.woff",
                },
            },
        },
        brand: {
            ttf: {
                default: {
                    regular: "tokens/assets/fonts/hind-siliguri-v6-latin-regular.ttf",
                    bold: "tokens/assets/fonts/hind-siliguri-v6-latin-600.ttf",
                },
                mono: {
                    regular: "tokens/assets/fonts/RobotoMono-Regular.ttf",
                },
            },
        },
    },
    icon: {
        brand: {
            appIcon: "tokens/assets/icons/Mittwald-M.svg",
            appIconMittolaus: "tokens/assets/icons/Mittolaus.svg",
        },
    },
    image: {
        brand: {
            logo: "tokens/assets/images/Mittwald-Logo.svg",
        },
    },
};
exports.color = {
    background: {
        default: "#f0f0f0",
        disabled: "#d2d2d2",
        gradient: {
            from: "#f0f0f0",
            to: "#fcfcfc",
        },
        button: {
            continue: {
                default: "#0072bc",
                hover: "#004775",
                active: "#003557",
                disabled: "#80b8dd",
            },
            complete: {
                default: "#72bf44",
                hover: "#457628",
                active: "#385f21",
                disabled: "#acd991",
            },
            destructive: {
                default: "#e32d2d",
                hover: "#8a0000",
                active: "#660000",
                disabled: "#ffbdbd",
            },
        },
        input: {
            default: "#f0f0f0",
            hovered: "#e4e4e5",
            disabled: "#e4e4e5",
            focused: "#ffffff",
            autofill: "#dae7ff",
            error: "#ffebeb",
        },
    },
    base: {
        transparent: "rgba(0, 0, 0, 0)",
        white: "#ffffff",
        black: "#000000",
        grey: {
            $50: "#f0f0f0",
            $100: "#e4e4e5",
            $200: "#d2d2d2",
            $300: "#93989c",
            $600: "#38393c",
            $700: "#38393c",
        },
        orange: {
            $50: "#feefd8",
            $100: "#fcc77e",
            $300: "#f99d1c",
            $600: "#a96404",
            $900: "#824e06",
        },
        red: {
            $50: "#ffebeb",
            $100: "#ffbdbd",
            $300: "#e32d2d",
            $600: "#8a0000",
            $900: "#660000",
        },
        marineBlue: {
            $50: "#f8fbfd",
            $100: "#e5f0f8",
            $200: "#80b8dd",
            $300: "#0072bc",
            $600: "#004775",
            $900: "#003557",
            gradient: {
                from: "#0072bc",
                to: "#003557",
            },
        },
        grassGreen: {
            $50: "#e6f4de",
            $100: "#acd991",
            $300: "#72bf44",
            $600: "#457628",
            $900: "#385f21",
        },
    },
    border: {
        primary: {
            default: "#004775",
        },
        secondary: {
            default: "#457628",
        },
        error: {
            default: "#8a0000",
        },
        section: {
            default: "#e4e4e5",
        },
        contentBoxSection: {
            default: "#e4e4e5",
        },
        horizontalRuler: {
            default: "#e4e4e5",
        },
        input: {
            default: "#f0f0f0",
            focused: "#0072bc",
            hovered: "#e4e4e5",
            disabled: "#e4e4e5",
            error: "#e32d2d",
        },
        checkbox: {
            default: "#d2d2d2",
            checked: "#004775",
            hovered: "#0072bc",
        },
    },
    brand: {
        primary: {
            default: "#0072bc",
            light: "#80b8dd",
            lighter: "#e5f0f8",
            dark: "#004775",
            darker: "#003557",
            gradient: {
                from: "#0072bc",
                to: "#003557",
            },
        },
        secondary: {
            default: "#72bf44",
            light: "#acd991",
            dark: "#457628",
            darker: "#385f21",
        },
    },
    font: {
        text: {
            default: "#38393c",
            error: "#e32d2d",
            disabled: "#d2d2d2",
            inverse: {
                default: "#ffffff",
            },
        },
        textLink: {
            default: "#0072bc",
            hover: "#004775",
            active: "#003557",
            inverse: {
                default: "#ffffff",
            },
        },
        h1: {
            default: "#004775",
            inverse: {
                default: "#ffffff",
            },
        },
        h2: {
            default: "#004775",
            inverse: {
                default: "#ffffff",
            },
        },
        h3: {
            default: "#004775",
            inverse: {
                default: "#ffffff",
            },
        },
        label: {
            default: "#93989c",
        },
        attributeHeadline: {
            default: "#38393c",
        },
        button: {
            link: {
                default: "#0072bc",
                hover: "#004775",
                active: "#003557",
                disabled: "#d2d2d2",
            },
            continue: {
                default: "#ffffff",
                hover: "#ffffff",
                active: "#ffffff",
                disabled: "#ffffff",
            },
            complete: {
                default: "#ffffff",
                hover: "#ffffff",
                active: "#ffffff",
                disabled: "#ffffff",
            },
            destructive: {
                default: "#ffffff",
                hover: "#ffffff",
                active: "#ffffff",
                disabled: "#ffffff",
            },
        },
        input: {
            default: "#38393c",
            placeholder: "#93989c",
            error: "#e32d2d",
            disabled: "#93989c",
        },
    },
    status: {
        error: {
            lighter: "#ffebeb",
            light: "#ffbdbd",
            default: "#e32d2d",
            dark: "#8a0000",
            darker: "#660000",
        },
        warning: {
            lighter: "#feefd8",
            light: "#fcc77e",
            default: "#f99d1c",
            dark: "#a96404",
            darker: "#824e06",
        },
        disabled: {
            light: "#e4e4e5",
            default: "#93989c",
            dark: "#38393c",
        },
        success: {
            lighter: "#e6f4de",
            light: "#acd991",
            default: "#72bf44",
            dark: "#457628",
            darker: "#385f21",
        },
        info: {
            lighter: "#f8fbfd",
            light: "#e5f0f8",
            default: "#0072bc",
            dark: "#004775",
            darker: "#003557",
        },
    },
};
exports.size = {
    font: {
        button: "16px",
        base: {
            default: "16px",
            s: "14px",
            m: "16px",
            l: "18px",
            xl: "23px",
        },
        text: "16px",
        smallText: "14px",
        pageTitle: {
            default: "32px",
            compact: "24px",
        },
        h1: {
            default: "23px",
            compact: "22px",
        },
        h2: "18px",
        h3: "16px",
        label: "14px",
        attributeHeadline: "14px",
        link: {
            default: "14px",
            inline: "16px",
        },
        codeBlock: "14px",
    },
    borderRadius: {
        button: "2px",
        contentBox: "4px",
        small: "2px",
        medium: "4px",
        large: "8px",
        xlarge: "12px",
    },
    avatar: {
        m: "40px",
        l: "96px",
        xl: "168px",
    },
    border: {
        base: {
            hairline: "1px",
            bold: "4px",
        },
        horizontalRuler: "1px",
    },
    lineHeight: {
        base: {
            default: "24px",
            s: "16px",
            m: "24px",
            l: "32px",
        },
        text: {
            default: "24px",
            squished: "16px",
        },
        smallText: {
            default: "24px",
            squished: "16px",
        },
        h1: {
            default: "32px",
            compact: "24px",
        },
        h2: {
            default: "24px",
        },
        h3: {
            default: "24px",
        },
        label: {
            default: "16px",
        },
        attributeHeadline: {
            default: "16px",
        },
    },
    iconBox: {
        s: "16px",
        m: "24px",
        xl: "64px",
    },
    icon: {
        s: "16px",
        m: "20px",
        xl: "64px",
    },
    boxShadow: {
        overlay: {
            default: "16px",
            elevated: "32px",
        },
        content: {
            default: "4px",
            elevated: "8px",
        },
        offCanvas: {
            default: "40px",
        },
    },
};
exports.spacing = {
    inset: {
        contentBox: "32px",
        none: "0px",
        xs: "4px",
        s: "8px",
        m: "16px",
        l: "24px",
        xl: "32px",
        xxl: "64px",
    },
    input: {
        vertical: "8px",
        horizontal: "16px",
    },
    base: {
        none: "0px",
        xxs: "2px",
        xs: "4px",
        s: "8px",
        m: "16px",
        l: "24px",
        xl: "32px",
        xxl: "64px",
    },
    insetSquish: {
        none: {
            x: "0px",
            y: "0px",
        },
        s: {
            x: "8px",
            y: "4px",
        },
        m: {
            x: "16px",
            y: "8px",
        },
        l: {
            x: "32px",
            y: "16px",
        },
        xl: {
            x: "64px",
            y: "32px",
        },
    },
    insetStretch: {
        none: {
            x: "0px",
            y: "0px",
        },
        s: {
            x: "8px",
            y: "12px",
        },
        m: {
            x: "16px",
            y: "24px",
        },
        l: {
            x: "24px",
            y: "32px",
        },
    },
    boxShadow: {
        overlay: {
            default: {
                y: "8px",
                x: "0px",
            },
            elevated: {
                y: "16px",
                x: "0px",
            },
        },
        content: {
            default: {
                y: "2px",
                x: "0px",
            },
            elevated: {
                y: "4px",
                x: "0px",
            },
        },
        offCanvas: {
            default: {
                y: "0px",
                x: "0px",
            },
        },
    },
};
exports.font = {
    brand: {
        sansSerif: {
            family: "HindSiliguri",
        },
        monoSpaced: {
            family: "RobotoMono",
        },
    },
};
exports.misc = {
    gradient: {
        degree: {
            base: 170,
        },
    },
};
exports.duration = {
    animation: {
        fast: 125,
        moderate: 200,
        slow: 500,
    },
};
exports.opacity = {
    base: {
        $20: 0.2,
        $50: 0.5,
        $80: 0.8,
    },
    disabled: 0.5,
    curtain: {
        default: 0.3,
    },
    boxShadow: {
        overlay: {
            default: 0.12,
            elevated: 0.14,
        },
        content: {
            default: 0.1,
        },
        offCanvas: {
            default: 0.3,
        },
    },
};
