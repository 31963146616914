"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEventListener = void 0;
var react_1 = require("react");
/**
 * Use this Hook in order to listen on target events
 *
 * _Notice!_ Use memorized handlers by wrapping them in `useCallback`
 */
var useEventListener = function (event, handler, target, opts) {
    if (target === void 0) { target = window; }
    if (opts === void 0) { opts = {}; }
    var _a = opts.deps, deps = _a === void 0 ? [] : _a;
    (0, react_1.useEffect)(function () {
        target.addEventListener(event, handler);
        return function () {
            target.removeEventListener(event, handler);
        };
    }, __spreadArray([event, handler, target], __read(deps), false));
};
exports.useEventListener = useEventListener;
