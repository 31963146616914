"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncValueAndValueController = void 0;
var useOnChange_1 = require("@mittwald/flow-lib/dist/hooks/useOnChange");
var react_1 = require("react");
var useListValueController_1 = require("../../../../hooks/useListValueController");
var useValueController_1 = require("../../../../hooks/useValueController");
var buildValueControllerOfValue = function (value) {
    return (Array.isArray(value)
        ? (0, useListValueController_1.useListValueController)(value)
        : (0, useValueController_1.useValueController)(value));
};
var buildValueControllerFromProps = function (props, emptyValue) {
    var value = props.value, controller = props.controller;
    var fallbackController = buildValueControllerOfValue(value === undefined ? emptyValue : value);
    return controller !== null && controller !== void 0 ? controller : fallbackController;
};
var useSyncValueAndValueController = function (props, emptyValue) {
    var _a = props.value, valueFromProps = _a === void 0 ? emptyValue : _a, onChange = props.onChange;
    var valueIsInProps = "value" in props;
    var useValueFromProps = valueIsInProps && !!onChange;
    var controller = buildValueControllerFromProps(props, emptyValue);
    (0, useOnChange_1.useOnChange)(function () {
        if (useValueFromProps) {
            controller.updateValue(valueFromProps);
        }
    }, [valueFromProps, controller.updateValue, useValueFromProps]);
    (0, react_1.useEffect)(function () {
        if (onChange) {
            return controller.onChange(onChange);
        }
    }, [controller.onChange, onChange]);
    var value = useValueFromProps ? valueFromProps : controller.watch();
    return {
        value: value,
        controller: controller,
    };
};
exports.useSyncValueAndValueController = useSyncValueAndValueController;
exports.default = exports.useSyncValueAndValueController;
