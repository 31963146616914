"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccessCheckKey = void 0;
var types_1 = require("./types");
var getAccessCheckKey = function (check) {
    return Array.isArray(check)
        ? check.map(exports.getAccessCheckKey).join("@@@")
        : check && (0, types_1.hasKey)(check)
            ? check.key
            : "undefined";
};
exports.getAccessCheckKey = getAccessCheckKey;
exports.default = exports.getAccessCheckKey;
