"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorRenderInfos = void 0;
var faExclamationTriangle_1 = require("@fortawesome/pro-regular-svg-icons/faExclamationTriangle");
var useTranslation_1 = require("../../../hooks/useTranslation");
var useVisibilityController_1 = require("../../../hooks/useVisibilityController");
var translation_1 = require("../../../lib/translation");
var initRenderingInfos_1 = require("./initRenderingInfos");
var runInspectors_1 = require("./runInspectors");
var maxSolutions = 5;
var useErrorRenderInfos = function (error) {
    var e_1, _a;
    var _b;
    var translate = (0, useTranslation_1.useTranslation)();
    var detailsVisibility = (0, useVisibilityController_1.useVisibilityController)(false, {
        hideOnClickOut: true,
        hideOnEsc: true,
    });
    var infosFromInspector = (0, runInspectors_1.runInspectors)(error);
    var infos = infosFromInspector !== null && infosFromInspector !== void 0 ? infosFromInspector : (0, initRenderingInfos_1.initRenderingInfos)();
    var isUnexpectedError = !infosFromInspector;
    if (!error) {
        return;
    }
    var errorInfoKeys = infos.infoTranslationKeys;
    errorInfoKeys.push("general");
    var titleTranslationKeys = errorInfoKeys.map(function (k) {
        return (0, translation_1.joinTranslationKeys)("error", "infos", k, "title");
    });
    var descriptionTranslationKeys = errorInfoKeys.map(function (k) {
        return (0, translation_1.joinTranslationKeys)("error", "infos", k, "description");
    });
    var actions = infos.actions;
    actions.push({ action: function () { return location.reload(); }, subject: "reload" });
    if (infos.details.length > 0) {
        actions.push({
            action: detailsVisibility.show,
            subject: "showDetails",
        });
    }
    var translatedSolutions = [];
    for (var i = 1; i <= maxSolutions; i++) {
        try {
            for (var errorInfoKeys_1 = (e_1 = void 0, __values(errorInfoKeys)), errorInfoKeys_1_1 = errorInfoKeys_1.next(); !errorInfoKeys_1_1.done; errorInfoKeys_1_1 = errorInfoKeys_1.next()) {
                var errorInfoKey = errorInfoKeys_1_1.value;
                var solution = translate((0, translation_1.joinTranslationKeys)("error", "infos", errorInfoKey, "solution", i), {
                    id: "translationFailed_sorry",
                    fallbackElement: null,
                });
                if (solution && !translatedSolutions.includes(solution)) {
                    translatedSolutions.push(solution);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (errorInfoKeys_1_1 && !errorInfoKeys_1_1.done && (_a = errorInfoKeys_1.return)) _a.call(errorInfoKeys_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    translatedSolutions.push(translate("error.solutions.general"));
    var icon = (_b = infos.icon) !== null && _b !== void 0 ? _b : faExclamationTriangle_1.faExclamationTriangle;
    var translatedActions = actions.map(function (action) { return (__assign(__assign({}, action), { subject: translate((0, translation_1.joinTranslationKeys)("error", "action", action.subject)) })); });
    var details = infos.details.map(function (details) { return (__assign(__assign({}, details), { subject: translate((0, translation_1.joinTranslationKeys)("error", "details", details.subject)) })); });
    return {
        icon: icon,
        actions: translatedActions,
        title: translate(titleTranslationKeys),
        description: translate(descriptionTranslationKeys),
        solutions: translatedSolutions,
        details: details,
        detailsVisibility: detailsVisibility,
        isUnexpectedError: isUnexpectedError,
    };
};
exports.useErrorRenderInfos = useErrorRenderInfos;
exports.default = exports.useErrorRenderInfos;
