"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMockErrorInspector = void 0;
var getObjectValue_1 = require("@mittwald/flow-lib/dist/object/getObjectValue");
var fetchMockErrorInspector = function (error, infos) {
    var message = (0, getObjectValue_1.getObjectValue)("message", error);
    if (typeof message !== "string" || !message.startsWith("fetch-mock")) {
        return false;
    }
    var noFallbackMatches = /fetch-mock: No fallback response defined for (.*?) to (.*)/.exec(message);
    if (noFallbackMatches) {
        infos.infoTranslationKeys.push("fetchMock.noFallback");
        if (noFallbackMatches[1]) {
            infos.details.push({
                subject: "api.method",
                value: noFallbackMatches[1],
            });
        }
        if (noFallbackMatches[2]) {
            infos.details.push({
                subject: "api.requestUrl",
                value: noFallbackMatches[2],
            });
        }
    }
    infos.infoTranslationKeys.push("fetchMock.unexpected");
};
exports.fetchMockErrorInspector = fetchMockErrorInspector;
exports.default = exports.fetchMockErrorInspector;
