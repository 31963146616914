"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.outerLayoutMixin = exports.outerLayoutMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var dimension_1 = __importStar(require("./dimension"));
var flexBoxItem_1 = __importStar(require("./flexBoxItem"));
var margin_1 = __importStar(require("./margin"));
var position_1 = __importStar(require("./position"));
exports.outerLayoutMixinPropsType = margin_1.marginMixinPropsType
    .extend(flexBoxItem_1.flexBoxItemMixinPropsType)
    .extend(position_1.positionMixinProps)
    .extend(dimension_1.dimensionMixinPropsType);
/** Combined mixin for component external layout props */
exports.outerLayoutMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), margin_1.default, flexBoxItem_1.default, dimension_1.default, position_1.default);
exports.default = exports.outerLayoutMixin;
var templateObject_1;
