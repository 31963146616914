"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resourceLoadingErrorInspector = void 0;
var reportingDataExtraction_1 = require("@mittwald/flow-lib/dist/errors/errorReporting/reportingDataExtraction");
var ResourceLoadingError_1 = require("@mittwald/flow-lib/dist/resources/ResourceLoadingError");
var resourceLoadingErrorInspector = function (error, infos) {
    if (!(error instanceof ResourceLoadingError_1.ResourceLoadingError)) {
        return false;
    }
    var infoTranslationKeys = infos.infoTranslationKeys;
    if (error instanceof ResourceLoadingError_1.NotFoundResourceLoadingError) {
        infoTranslationKeys.push("resourceLoading.notFound");
    }
    else if (error instanceof ResourceLoadingError_1.NoAccessResourceLoadingError) {
        infoTranslationKeys.push("resourceLoading.noAccess");
    }
    else {
        infoTranslationKeys.push("resourceLoading.unexpected");
    }
};
exports.resourceLoadingErrorInspector = resourceLoadingErrorInspector;
(0, reportingDataExtraction_1.registerErrorReportingDataExtractionFn)(function (error) {
    if (error instanceof ResourceLoadingError_1.ResourceLoadingError) {
        var isUnexpectedError = error instanceof ResourceLoadingError_1.UnexpectedResourceLoadingError;
        return {
            noReport: !isUnexpectedError,
        };
    }
});
exports.default = exports.resourceLoadingErrorInspector;
