"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formActionBarPropsType = exports.actionBarPropsType = void 0;
var Box_1 = require("../Box");
exports.actionBarPropsType = Box_1.boxPropsType.complete({
    abort: true,
    disabled: true,
    primary: true,
    secondary: true,
    abortOnEsc: true,
});
exports.formActionBarPropsType = exports.actionBarPropsType.complete({
    form: true,
});
