import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import { config } from "../../config";
import { MittwaldApiClient } from "../Mittwald";

export const mittwaldApiHttpClient = new KyClient({
    ky: {
        prefixUrl: config.apiUri,
    },
});

export const mittwaldApiClient = new MittwaldApiClient(mittwaldApiHttpClient);
MittwaldApiClient.setInstance(mittwaldApiClient);

export default mittwaldApiClient;
