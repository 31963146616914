"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFieldErrorType = void 0;
var getObjectValue_1 = require("@mittwald/flow-lib/dist/object/getObjectValue");
var Form_1 = require("../../Form");
var useFieldErrorType = function (fieldName) {
    var formState = (0, Form_1.useFormContext)().formState;
    if (!fieldName) {
        return;
    }
    var error = (0, getObjectValue_1.getObjectValue)(fieldName, formState.errors);
    if (!error) {
        return;
    }
    var errorType = "type" in error ? error["type"] : undefined;
    var errorMessage = "message" in error ? error["message"] : undefined;
    if (typeof errorMessage === "string" && errorMessage.length > 0) {
        return errorMessage;
    }
    return typeof errorType === "string" ? errorType : undefined;
};
exports.useFieldErrorType = useFieldErrorType;
