"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverlayAnimation = void 0;
var framer_motion_1 = require("framer-motion");
var react_1 = __importStar(require("react"));
var useCompactMode_1 = require("../../hooks/useCompactMode");
var useVisibilityController_1 = require("../../hooks/useVisibilityController");
var Box_1 = require("../Box");
var OffCanvas_1 = require("../OffCanvas");
var Portal_1 = require("../Portal");
var background_1 = require("./background");
var Styled = __importStar(require("./styled"));
var zoom_1 = require("./zoom");
/**
 * **WIP Status**
 *
 * The default animation used for overlays like Modals or Full-Screen-Overlays.
 *
 * This animation also displays a curtain to slightly dim the underlying page.
 */
var OverlayAnimation = function (props) {
    var children = props.children, className = props.className, _a = props.disableCompact, disableCompact = _a === void 0 ? false : _a, externalVisibility = props.visibility, _b = props.speed, speed = _b === void 0 ? 1 : _b, _c = props.disableDrag, disableDrag = _c === void 0 ? true : _c, rest = __rest(props, ["children", "className", "disableCompact", "visibility", "speed", "disableDrag"]);
    var boxProps = Box_1.boxPropsType.pickFrom(rest);
    var compactMode = !disableCompact && (0, useCompactMode_1.useCompactMode)();
    var variantsProps = {
        speed: speed,
    };
    /**
     * This controller is a proxy around the Visibility Controller provided by the
     * props. It defers the hiding of the Portal, so that the exit animation has
     * time to shine.
     */
    var portalVisibilityController = (0, useVisibilityController_1.useVisibilityController)(externalVisibility.visible);
    (0, react_1.useEffect)(function () {
        // only pass external "show" to innerVisibility
        if (externalVisibility.visible) {
            portalVisibilityController.setVisibility(true);
        }
        else {
            externalVisibility.updateAnimationState("animating");
        }
    }, [externalVisibility.visible]);
    var exitAnimationDoneHandler = function () {
        // hide the portal when the inner animation is done
        portalVisibilityController.setVisibility(false);
        externalVisibility.updateAnimationState("done");
    };
    var AnimatePresenceWithChildren = framer_motion_1.AnimatePresence;
    var AnimationElement = (react_1.default.createElement(AnimatePresenceWithChildren, { onExitComplete: exitAnimationDoneHandler }, externalVisibility.visible && (react_1.default.createElement(background_1.OverlayBackgroundContainer
    // initial value
    , { 
        // initial value
        _alignItems: "center", _flexDirection: "row", _justifyContent: "center", animate: "visible", custom: variantsProps, exit: "hidden", initial: "hidden", opacity: 0, variants: background_1.overlayBackgroundAnimation },
        react_1.default.createElement(zoom_1.OverlayZoomContainer, __assign({ className: className, custom: variantsProps, variants: zoom_1.overlayZoomAnimation }, boxProps, { ref: externalVisibility.ref }), children)))));
    var CompactAnimationElement = (react_1.default.createElement(OffCanvas_1.OffCanvas, __assign({}, boxProps, { _m: undefined, _maxW: undefined, _mt: "m", className: className, disableDrag: disableDrag, position: "bottom", visibility: externalVisibility, onExitComplete: exitAnimationDoneHandler }),
        react_1.default.createElement(Styled.OffCanvasBackground, { _bgColor: "white", _flexDirection: "column", _grow: true, _minH: "100%" }, children)));
    return (react_1.default.createElement(Portal_1.Portal, { visibility: portalVisibilityController }, compactMode ? CompactAnimationElement : AnimationElement));
};
exports.OverlayAnimation = OverlayAnimation;
exports.default = exports.OverlayAnimation;
