"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLinkActionController = exports.isActionController = exports.isDetailedAction = exports.isLinkAction = exports.isCallAction = exports.isFormAction = exports.actionConfirmationModalOptionsType = void 0;
var Props_1 = require("../../lib/Props");
exports.actionConfirmationModalOptionsType = new Props_1.PropsType({
    confirmationModalContent: true,
    confirmationModalCustomProps: true,
    confirmationModalDestructive: true,
    confirmationModalIcon: true,
    confirmationModalTranslationValues: true,
    showConfirmationModal: true,
    translationKey: true,
    showSuccessFeedback: true,
});
function isFormAction(action) {
    return typeof action === "object" && "formState" in action;
}
exports.isFormAction = isFormAction;
function isCallAction(action) {
    return typeof action === "function";
}
exports.isCallAction = isCallAction;
function isLinkAction(action) {
    return ((typeof action === "object" && "url" in action) ||
        typeof action === "string");
}
exports.isLinkAction = isLinkAction;
function isDetailedAction(action) {
    return (typeof action === "object" && !isFormAction(action) && !isLinkAction(action));
}
exports.isDetailedAction = isDetailedAction;
function isActionController(action) {
    return typeof action === "object" && "actionType" in action;
}
exports.isActionController = isActionController;
function isLinkActionController(unknownController) {
    return (typeof unknownController === "object" &&
        "actionType" in unknownController &&
        unknownController.actionType === "linkAction");
}
exports.isLinkActionController = isLinkActionController;
