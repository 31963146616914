"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.executeAccessCheck = void 0;
var types_1 = require("./types");
var executeAccessCheck = function (check) {
    if (check === undefined) {
        return true;
    }
    if (typeof check === "function") {
        return check();
    }
    if ((0, types_1.hasKey)(check)) {
        return (0, exports.executeAccessCheck)(check.check);
    }
    return check.useWatchData();
};
exports.executeAccessCheck = executeAccessCheck;
exports.default = exports.executeAccessCheck;
