"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeConfig = void 0;
var isValidEnvVarName = function (name) {
    return /\${REACT_APP_(_?[A-Z])+}/.test(name);
};
var getEnvVarNameFromValue = function (value) { var _a; return (_a = /\${(REACT_APP_(_?[A-Z])+)}/.exec(value)) === null || _a === void 0 ? void 0 : _a[1]; };
var validateEnvVarName = function (name) {
    if (!isValidEnvVarName(name)) {
        throw new Error("Validating '".concat(name, "' failed. Environment variable names with config values must be of format '${REACT_APP_*}'."));
    }
};
var validConfigValueOrUndefined = function (value) {
    return typeof value === "string" &&
        value.length > 0 &&
        !isValidEnvVarName(value)
        ? value
        : undefined;
};
var isPreBuild = function () {
    return process.env["npm_lifecycle_event"] === "before-build-script";
};
var isDevServer = function () { return process.env["NODE_ENV"] === "development"; };
var getDefaultValue = function (conf, key, env) {
    var _a, _b;
    if (env === void 0) { env = "dev"; }
    var defaults = (_a = conf[key]) === null || _a === void 0 ? void 0 : _a.defaults;
    return typeof defaults === "object"
        ? (_b = defaults[env]) !== null && _b !== void 0 ? _b : defaults.dev
        : defaults;
};
var getValueReplacedByEnvVar = function (conf, key) {
    var _a;
    var start = (_a = conf[key]) === null || _a === void 0 ? void 0 : _a.start;
    if (!start) {
        return;
    }
    var envVarName = getEnvVarNameFromValue(start);
    if (!envVarName) {
        return;
    }
    return process.env[envVarName];
};
var getResolvedValueFromConf = function (conf, key, env) {
    var _a, _b, _c;
    if (env === void 0) { env = "dev"; }
    var value = conf[key];
    return ((_c = (_b = (_a = validConfigValueOrUndefined(value === null || value === void 0 ? void 0 : value.start)) !== null && _a !== void 0 ? _a : validConfigValueOrUndefined(value === null || value === void 0 ? void 0 : value.build)) !== null && _b !== void 0 ? _b : process.env[key]) !== null && _c !== void 0 ? _c : getDefaultValue(conf, key, env));
};
/**
 * Bundles configuration values from env vars. Env vars can be either defined at
 * build-time or startup.
 *
 * Default values can be setup for different environments (dev, prod, ...). The
 * app env itself can be setup with the env-var "REACT_APP_ENV" and has "dev" as
 * default value.
 *
 * ```typescript
 * export const config = makeConfig({
 *     // must be prefixed with REACT_APP_
 *     containerVar: {
 *         start: "${REACT_APP_API_URL}",
 *         defaults: "https://api.mittwald.de",
 *     },
 *     // must be prefixed with REACT_APP_
 *     buildVar: {
 *         build: "${REACT_APP_THEME},
 *         defaults: "light",
 *     },
 *     staticConfigVar: {
 *         defaults: "a static config value",
 *     },
 *     devLogEnabled: {
 *         defaults: {
 *             dev: true,
 *             prod: false,
 *         },
 *     },
 * });
 * ```
 */
var makeConfig = function (conf) {
    var e_1, _a;
    var _b, _c, _d;
    conf = __assign(__assign({}, conf), { appEnv: {
            start: "${REACT_APP_ENV}",
            defaults: "dev",
        } });
    var result = {};
    var preBuildTime = isPreBuild();
    var envsubstWhitelist = [];
    var localEnvVars = {};
    var appEnv = preBuildTime
        ? "undefined"
        : (_b = getResolvedValueFromConf(conf, "appEnv", "undefined")) === null || _b === void 0 ? void 0 : _b.toString();
    try {
        for (var _e = __values(Object.entries(conf)), _f = _e.next(); !_f.done; _f = _e.next()) {
            var _g = __read(_f.value, 2), key = _g[0], value = _g[1];
            var build = value.build, defaults = value.defaults, start = value.start;
            if (preBuildTime) {
                /**
                 * Case 1: pre-build-time At pre-build-time this function does two things:
                 *
                 * 1. Write a .local.env to make config value available for the build script
                 *    (see https://create-react-app.dev/docs/adding-custom-environment-variables/)
                 * 2. Write the envsubst_whitelist.txt to whitelist values for the envsubst
                 *    command used at container startup (entrypoint script)
                 */
                /**
                 * At build-time build-values are written to a local env-file and
                 * start-values are written to the substenv-whitelist
                 */
                if (typeof build === "string") {
                    validateEnvVarName(build);
                    if (typeof defaults === "string") {
                        // writes the default values to .env.local, which is additionally loaded to .env
                        envsubstWhitelist.push(build);
                        var varName = build.slice(2, -1);
                        localEnvVars[varName] = (_c = process.env[varName]) !== null && _c !== void 0 ? _c : defaults;
                    }
                }
                if (typeof start === "string") {
                    validateEnvVarName(start);
                    envsubstWhitelist.push(start);
                }
            }
            else if (isDevServer()) {
                /**
                 * Case 2: dev-server When starting the dev-server, the config is filled
                 * with the dev-values
                 */
                result[key] =
                    (_d = getDefaultValue(conf, key, "dev")) !== null && _d !== void 0 ? _d : getValueReplacedByEnvVar(conf, key);
            }
            else {
                /**
                 * Case 3: running build (e.g. serving via nginx) When running in a build,
                 * the env-vars occurring in the build-files (makeConfig(...)) should be
                 * replaced beforehand with 'envsubst'(e.g. in a Docker entrypoint
                 * script). 'getResolvedValueFromConf' will get either a valid 'start' or
                 * 'build' valid, or will fallback to the default value.
                 */
                result[key] = getResolvedValueFromConf(conf, key, appEnv);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
        }
        finally { if (e_1) throw e_1.error; }
    }
    // see 'case 1' above
    if (preBuildTime) {
        var writeLocalEnvFile = require("./writeLocalEnvFile").writeLocalEnvFile;
        writeLocalEnvFile(localEnvVars);
        if (envsubstWhitelist.length > 0) {
            var writeFileSync = require("fs").writeFileSync;
            var join = require("path").join;
            // The 'envsubst_whitelist.txt' is used by the docker entrypoint script. This
            // script is not provided by this function.
            writeFileSync(join(process.cwd(), "docker", "nginx", "envsubst_whitelist.txt"), envsubstWhitelist.join(" "), {
                encoding: "utf8",
            });
        }
    }
    return result;
};
exports.makeConfig = makeConfig;
exports.default = exports.makeConfig;
