"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointPropValue = void 0;
var useGetBreakpointPropValue_1 = require("./useGetBreakpointPropValue");
/**
 * Use this hook if you want to get the current `BreakpointVariant`
 *
 * This hook only works inside a `<Breakpoints />` component
 */
var useBreakpointPropValue = function (value) {
    return (0, useGetBreakpointPropValue_1.useGetBreakpointPropValue)()(value);
};
exports.useBreakpointPropValue = useBreakpointPropValue;
exports.default = exports.useBreakpointPropValue;
