"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.thumbStyles = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
exports.thumbStyles = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n\n  &.dark {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n\n  &.dark {\n    background-color: ", ";\n  }\n"])), variables_1.color.base.grey.$200, variables_1.size.borderRadius.large, variables_1.color.base.grey.$300);
var templateObject_1;
