"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRoutingConfirmationContext = exports.routingConfirmationContext = void 0;
var react_1 = require("react");
exports.routingConfirmationContext = (0, react_1.createContext)({
    needsConfirmation: false,
    blockHistory: function () { },
    unblockHistory: function () { },
    confirm: function () { },
    abort: function () { },
});
var useRoutingConfirmationContext = function () {
    return (0, react_1.useContext)(exports.routingConfirmationContext);
};
exports.useRoutingConfirmationContext = useRoutingConfirmationContext;
