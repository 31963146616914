import { AppBootstrap } from "@mittwald/flow-components/dist/components/AppBootstrap";
import React, { FC } from "react";
import "./api/clients/mittwald";
import "./api/clients/mittwaldLegacy";
import { autorunAppInitialization } from "./autorun/autorunAppInitialization";
import { config } from "./config";

import defaultLanguage from "./i18n/de";
import { pageTree } from "./pages/pageTree";
import varnishStore from "./store/varnish";

autorunAppInitialization();

const App: FC = () => (
    <AppBootstrap
        defaultLanguage={defaultLanguage}
        environment={config.environment}
        featureFlags={["useDemoData", "varnishDemodata"]}
        importLanguageFile={(language) => import(`./i18n/${language}`)}
        name="varnish-editor"
        pageTree={pageTree}
        persistedStores={[["varnishEditor", varnishStore]]}
        sentryDsn={config.sentryDsn}
        version={config.version}
    />
);

export default App;
