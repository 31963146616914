"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeLocalEnvFile = void 0;
var fs_1 = require("fs");
var path_1 = require("path");
function writeLocalEnvFile(content) {
    if (content === void 0) { content = {}; }
    var mergedEnvFormat = Object.entries(content)
        .map(function (_a) {
        var _b = __read(_a, 2), name = _b[0], value = _b[1];
        return "".concat(name, "=").concat(value);
    })
        .join("\n");
    (0, fs_1.writeFileSync)((0, path_1.join)(process.cwd(), ".env.local"), mergedEnvFormat, {
        encoding: "utf8",
    });
}
exports.writeLocalEnvFile = writeLocalEnvFile;
