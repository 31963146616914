"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.borderRadiusMixin = exports.borderRadiusMixinPropsType = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
exports.borderRadiusMixinPropsType = new Props_1.PropsType({
    _borderRadius: true,
});
exports.borderRadiusMixin = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), (0, breakpointProp_1.breakpointProp)("_borderRadius", function (v) {
    return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        border-radius: ", ";\n        // Safari hack for border radius in combination with overflow:hidden\n        // see the sad story here https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b?permalink_comment_id=3939446#gistcomment-3939446\n        backface-visibility: hidden;\n        transform: translate3d(0, 0, 0);\n      "], ["\n        border-radius: ", ";\n        // Safari hack for border radius in combination with overflow:hidden\n        // see the sad story here https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b?permalink_comment_id=3939446#gistcomment-3939446\n        backface-visibility: hidden;\n        transform: translate3d(0, 0, 0);\n      "])), v === "circle" ? "50%" : variables_1.size.borderRadius[v]);
}));
var templateObject_1, templateObject_2;
