"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyListener = void 0;
var useEventListener_1 = require("../useEventListener/useEventListener");
/**
 * Use this Hook to listen on Keyboard Events
 *
 * _Notice!_ Use memorized handlers by wrapping them in `useCallback`
 */
var useKeyListener = function (key, handler, bindOn, options) {
    if (options === void 0) { options = {}; }
    var _a = options.target, target = _a === void 0 ? document.body : _a;
    var keys = Array.isArray(key) ? key : [key];
    var onKeyHandler = function (event) {
        var activeKeyCode = event.code;
        // Skip the handler if key does not match
        if (!keys.includes(activeKeyCode)) {
            return;
        }
        handler(event);
    };
    (0, useEventListener_1.useEventListener)(bindOn, onKeyHandler, target);
};
exports.useKeyListener = useKeyListener;
