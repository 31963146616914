"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elasticScrollingThreshold = exports.swipeAwayThreshold = exports.transition = exports.canvasBoxShadowSize = exports.minCanvasSize = exports.swipeDetectionThresholdPx = void 0;
// How much pixels a user has to drag the canvas, so the motion is recognized as a "swipe-away"
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var polished_1 = require("polished");
exports.swipeDetectionThresholdPx = 75; // If the canvas is too small, swiping it away will not be possible
var minCanvasSizePx = exports.swipeDetectionThresholdPx + 50;
exports.minCanvasSize = "min(100%, ".concat(minCanvasSizePx, "px");
exports.canvasBoxShadowSize = (0, polished_1.stripUnit)(variables_1.size.boxShadow.overlay.default);
exports.transition = {
    duration: variables_1.duration.animation.slow / 1000,
    ease: "easeInOut",
};
exports.swipeAwayThreshold = 0.25;
exports.elasticScrollingThreshold = 0.2;
