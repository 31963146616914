"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRendererContext = exports.rendererContext = void 0;
var react_1 = require("react");
var defaultRendererContext = {
    overlayVisibility: {},
    navigationMenuVisibility: {},
};
exports.rendererContext = (0, react_1.createContext)(defaultRendererContext);
var useRendererContext = function () {
    return (0, react_1.useContext)(exports.rendererContext);
};
exports.useRendererContext = useRendererContext;
