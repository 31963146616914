"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTranslateFuncOptions = exports.isI18nDefinition = exports.isStringOverride = void 0;
var react_1 = require("react");
function isStringOverride(i18n) {
    return typeof i18n === "object" && "text" in i18n;
}
exports.isStringOverride = isStringOverride;
var isI18nDefinition = function (test) {
    return (typeof test === "string" ||
        isStringOverride(test) ||
        Array.isArray(test) ||
        (0, react_1.isValidElement)(test) ||
        (typeof test === "object" && "id" in test));
};
exports.isI18nDefinition = isI18nDefinition;
var isTranslateFuncOptions = function (test) {
    return !(0, exports.isI18nDefinition)(test) && typeof test === "object";
};
exports.isTranslateFuncOptions = isTranslateFuncOptions;
