"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToTopContextProvider = exports.useScrollToTopDependencies = exports.scrollToTopContext = void 0;
var react_1 = require("react");
exports.scrollToTopContext = (0, react_1.createContext)(undefined);
var useScrollToTopDependencies = function () {
    return (0, react_1.useContext)(exports.scrollToTopContext);
};
exports.useScrollToTopDependencies = useScrollToTopDependencies;
exports.ScrollToTopContextProvider = exports.scrollToTopContext.Provider;
exports.default = exports.ScrollToTopContextProvider;
