"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.shadowMixin = exports.shadowMixinPropsType = void 0;
var boxShadow_1 = require("@mittwald/flow-styles/dist/mixins/boxShadow");
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
exports.shadowMixinPropsType = new Props_1.PropsType({
    _shadow: true,
});
exports.shadowMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), (0, breakpointProp_1.breakpointProp)("_shadow", function (v) {
    switch (v) {
        case "overlayBox":
            return boxShadow_1.overlayBoxShadow;
        case "elevatedOverlayBox":
            return boxShadow_1.elevatedOverlayBoxShadow;
        case "elevatedContentBox":
            return boxShadow_1.elevatedContentBoxShadow;
        case "contentBox":
            return boxShadow_1.contentBoxShadow;
        case "offCanvas":
            return boxShadow_1.offCanvasBoxShadow;
        default:
            return "";
    }
}));
var templateObject_1;
