"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalContentPropsType = void 0;
var Props_1 = require("../../../../lib/Props");
exports.modalContentPropsType = new Props_1.PropsType({
    headline: true,
    headlineIcon: true,
    info: true,
    success: true,
    disabled: true,
    warning: true,
    description: true,
    linkAction: true,
    error: true,
});
