"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexBoxMixin = exports.flexBoxMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var useBreakpointPropValue_1 = require("../hooks/useBreakpointPropValue");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.flexBoxMixinPropsType = new Props_1.PropsType({
    _alignContent: true,
    _inlineFlex: true,
    _flexDirection: true,
    _flexWrap: true,
    _alignItems: true,
    _itemGap: true,
    _justifyContent: true,
});
exports.flexBoxMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  // see https://css-tricks.com/flexbox-truncated-text/#the-solution-is-min-width-0-on-the-flex-child\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", ";\n  // see https://css-tricks.com/flexbox-truncated-text/#the-solution-is-min-width-0-on-the-flex-child\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n"])), (0, breakpointProp_1.breakpointProp)("_flexDirection", function (v) { return "flex-direction: ".concat(v, ";"); }), (0, breakpointProp_1.breakpointProp)("_flexDirection", function (v, p) { return "display: ".concat(p._inlineFlex ? "inline-flex" : "flex", ";"); }), (0, breakpointProp_1.breakpointProp)("_flexDirection", function (v) {
    return v === "row"
        ? "& > * { min-width: 0; };"
        : v === "column"
            ? "& > * { min-height: 0; };"
            : undefined;
}), (0, breakpointProp_1.breakpointProp)("_flexWrap", function (v) { return (v ? "flex-wrap: wrap;" : undefined); }), (0, breakpointProp_1.breakpointProp)("_alignItems", function (v) { return "align-items: ".concat(v === "default" ? "stretch" : v, ";"); }), (0, breakpointProp_1.breakpointProp)("_alignContent", function (v) { return "align-content: ".concat(v, ";"); }), (0, breakpointProp_1.breakpointProp)("_justifyContent", function (v) { return "justify-content: ".concat(v, ";"); }), (0, breakpointProp_1.breakpointProp)("_itemGap", function (v, p) {
    var px = (0, formatters_1.spacingPx)(v);
    var flexWrap = (0, useBreakpointPropValue_1.useBreakpointPropValue)(p._flexWrap);
    if (v === 0) {
        return undefined;
    }
    if (flexWrap) {
        return "\n                & > * {\n                    margin-left: ".concat(px, ";\n                    margin-top: ").concat(px, ";\n                }\n            ");
    }
    else {
        var flexDirection = (0, useBreakpointPropValue_1.useBreakpointPropValue)(p._flexDirection);
        if (flexDirection === "column-reverse" ||
            flexDirection == "row-reverse") {
            // select every element before but not self
            return "\n                    & > *:not(:last-child) {\n                        margin-".concat(flexDirection === "row-reverse" ? "left" : "top", ": ").concat(px, ";\n                    }\n                ");
        }
        // select every element after but not self
        return "\n                & > * + * {\n                    margin-".concat(flexDirection === "row" ? "left" : "top", ": ").concat(px, ";\n                }\n            ");
    }
}));
exports.default = exports.flexBoxMixin;
var templateObject_1;
