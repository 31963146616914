"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetBreakpointPropValue = void 0;
var context_1 = require("../components/Breakpoints/context");
/**
 * Use this hook if you want to get the current `BreakpointVariant`
 *
 * This hook only works inside a `<Breakpoints />` component
 */
var useGetBreakpointPropValue = function () {
    var index = (0, context_1.useBreakpointsContext)().index;
    return function (value) {
        if (Array.isArray(value)) {
            if (index === -1) {
                console.warn("Breakpoint values (e.g. [1,3]) are only support in (sub)elements of the Breakpoint component");
                return value[0];
            }
            return value[index];
        }
        return value;
    };
};
exports.useGetBreakpointPropValue = useGetBreakpointPropValue;
exports.default = exports.useGetBreakpointPropValue;
