"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpointProp = void 0;
var context_1 = require("../components/Breakpoints/context");
var breakpoint_1 = require("./breakpoint");
/** Use this mixin, if you build a component with `BreakpointVariants` */
var breakpointProp = function (propName, format) {
    return function (props) {
        var contextClassName = (0, context_1.useBreakpointsContext)().contextClassName;
        var prop = props[propName];
        if (prop === undefined || prop === null) {
            return "";
        }
        if (Array.isArray(prop)) {
            if (contextClassName) {
                var formattedCssValues = prop.map(function (val) {
                    return format(val, props);
                });
                return formattedCssValues
                    .map(function (css, i) { return (0, breakpoint_1.breakpoint)(i, css)(); })
                    .join("\n");
            }
            console.warn("Breakpoint values (".concat(prop, ") are only support in (sub)elements of the Breakpoint component"));
            prop = prop[0];
        }
        if (prop === null || prop === undefined) {
            return "";
        }
        var cssCode = format(prop, props);
        if (cssCode === null || cssCode === undefined || cssCode === false) {
            return "";
        }
        return cssCode;
    };
};
exports.breakpointProp = breakpointProp;
