"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPropsContext = void 0;
var react_1 = require("react");
var createDefaultPropsContext = function () {
    var context = (0, react_1.createContext)({});
    var Provider = function (props) {
        var children = props.children, restProps = __rest(props, ["children"]);
        return (0, react_1.createElement)(context.Provider, { value: restProps }, children);
    };
    var merge = function (props) {
        var contextProps = (0, react_1.useContext)(context);
        return __assign(__assign({}, contextProps), props);
    };
    return {
        Provider: Provider,
        merge: merge,
    };
};
exports.createDefaultPropsContext = createDefaultPropsContext;
