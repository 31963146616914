"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectChildrenAccessChecks = exports.getAccessChecksFromActions = void 0;
var react_1 = __importStar(require("react"));
var useActionController_1 = require("../../hooks/useActionController");
var getAccessChecksFromActions = function (action) {
    var actions = Array.isArray(action) ? action : [action];
    return actions.map(function (a) {
        if ((0, useActionController_1.isActionController)(a)) {
            return a.detailedAction.accessCheck;
        }
        else if ((0, useActionController_1.isDetailedAction)(a)) {
            return a.accessCheck;
        }
        else {
            return undefined;
        }
    });
};
exports.getAccessChecksFromActions = getAccessChecksFromActions;
var collectChildrenAccessChecks = function (children) {
    var collectedAccessChecks = [];
    react_1.default.Children.forEach(children, function (child) {
        if ((0, react_1.isValidElement)(child)) {
            var action_1 = child.props.action;
            if ((0, useActionController_1.isActionController)(action_1)) {
                collectedAccessChecks.push(function () { return action_1.hasAccess; });
            }
            else if ((0, useActionController_1.isDetailedAction)(action_1)) {
                collectedAccessChecks.push(action_1.accessCheck);
            }
            else if ((0, useActionController_1.isCallAction)(action_1)) {
                collectedAccessChecks.push(undefined);
            }
            else if ((0, useActionController_1.isLinkAction)(action_1)) {
                collectedAccessChecks.push(undefined);
            }
            collectedAccessChecks.push.apply(collectedAccessChecks, __spreadArray([], __read((0, exports.collectChildrenAccessChecks)(child.props.children)), false));
        }
    });
    return collectedAccessChecks;
};
exports.collectChildrenAccessChecks = collectChildrenAccessChecks;
