"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultModalProps = exports.modalPropsType = void 0;
var Box_1 = require("../Box");
var types_1 = require("./components/ModalContent/types");
var types_2 = require("./components/ModalFooter/types");
exports.modalPropsType = Box_1.boxPropsType.complete({
    closeOnClickOut: true,
    closeOnEsc: true,
    name: true,
    _size: true,
    visibility: true,
});
exports.defaultModalProps = exports.modalPropsType
    .extend(types_1.modalContentPropsType)
    .extend(types_2.modalFooterPropsType)
    .complete({
    postContent: true,
    preContent: true,
});
