"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoutingConfirmationProvider = void 0;
var react_1 = __importStar(require("react"));
var RoutingConfirmationContext_1 = require("../RoutingConfirmationContext");
var RoutingConfirmationProvider = function (props) {
    var history = props.history, children = props.children;
    var _a = __read((0, react_1.useState)(false), 2), needsConfirmation = _a[0], setNeedsConfirmation = _a[1];
    var unblockHistory = (0, react_1.useRef)(function () { });
    var lastBlockedNavigation = (0, react_1.useRef)();
    var blockHistory = function () {
        unblockHistory.current = history.block(function (transition) {
            lastBlockedNavigation.current = transition;
            setNeedsConfirmation(true);
        });
    };
    var confirm = function () {
        var _a;
        setNeedsConfirmation(false);
        unblockHistory.current();
        (_a = lastBlockedNavigation.current) === null || _a === void 0 ? void 0 : _a.retry();
    };
    var abort = function () {
        setNeedsConfirmation(false);
    };
    var context = {
        needsConfirmation: needsConfirmation,
        blockHistory: blockHistory,
        unblockHistory: function () {
            unblockHistory.current();
        },
        confirm: confirm,
        abort: abort,
    };
    return (react_1.default.createElement(RoutingConfirmationContext_1.routingConfirmationContext.Provider, { value: context }, children));
};
exports.RoutingConfirmationProvider = RoutingConfirmationProvider;
exports.default = exports.RoutingConfirmationProvider;
