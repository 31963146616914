"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryParamsStore = exports.model = void 0;
var mobx_state_tree_1 = require("mobx-state-tree");
exports.model = mobx_state_tree_1.types
    .model({
    storedQueryParams: mobx_state_tree_1.types.map(mobx_state_tree_1.types.map(mobx_state_tree_1.types.maybe(mobx_state_tree_1.types.string))),
})
    .actions(function (self) { return ({
    storeQueryParams: function (storageKey, params) {
        self.storedQueryParams.set(storageKey, params);
    },
    onLoad: function () { },
}); })
    .views(function (self) { return ({
    getQueryParams: function (storageKey) {
        var _a, _b;
        return ((_b = (_a = self.storedQueryParams.get(storageKey)) === null || _a === void 0 ? void 0 : _a.toJSON()) !== null && _b !== void 0 ? _b : {});
    },
}); });
exports.queryParamsStore = exports.model.create({
    storedQueryParams: {},
});
exports.default = exports.queryParamsStore;
