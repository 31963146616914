"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollToTop = void 0;
var ScrollToTopContextProvider_1 = require("../components/ScrollToTopContextProvider");
var useOnChange_1 = require("./useOnChange");
/**
 * Scrolls the given target to top if the dependency list of the
 * ScrollToTopContextProvider changes
 */
var useScrollToTop = function (target) {
    var _a;
    var scrollTopDependencies = (_a = (0, ScrollToTopContextProvider_1.useScrollToTopDependencies)()) !== null && _a !== void 0 ? _a : [];
    (0, useOnChange_1.useOnChange)(function () {
        if (target) {
            target.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, scrollTopDependencies);
};
exports.useScrollToTop = useScrollToTop;
exports.default = exports.useScrollToTop;
