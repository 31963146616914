"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitFunctionalModal = void 0;
var react_1 = require("react");
var FunctionalModalContext_1 = require("../FunctionalModalContext");
var libs_1 = require("../libs");
/** Internally used by the Modal component */
var useInitFunctionalModal = function (visibility) {
    var modalKey = (0, FunctionalModalContext_1.useFunctionalModalContextKey)();
    /** Functionally triggered Modals should be initially visible */
    (0, react_1.useEffect)(function () {
        if (modalKey && visibility.hidden) {
            visibility.show();
        }
    }, [modalKey]);
    /** After exit animation is done, kick the modal from the store */
    (0, react_1.useEffect)(function () {
        if (modalKey && visibility.exitAnimationState === "done") {
            libs_1.functionalModalsStore.removeModal(modalKey);
        }
    }, [modalKey, visibility]);
};
exports.useInitFunctionalModal = useInitFunctionalModal;
exports.default = exports.useInitFunctionalModal;
