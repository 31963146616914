"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTextDefaultProps = exports.getIconDefaultProps = void 0;
var react_1 = require("react");
var H1_1 = require("../H1");
var H2_1 = require("../H2");
var Label_1 = require("../Label");
var Text_1 = require("../Text");
var getIconDefaultProps = function (props) {
    var textElement = react_1.Children.only(props.children);
    if (!textElement) {
        return {};
    }
    var isH1 = textElement.type === H1_1.H1;
    var isH2 = textElement.type === H2_1.H2;
    var isText = textElement.type === Text_1.Text;
    var isLabel = textElement.type === Label_1.Label;
    var defaultIconSize = isH1 || isH2 ? "m" : "s";
    var hasDefinedColor = typeof textElement.props._color === "string";
    var shouldInheritColorFromTextElement = !isText && !isLabel;
    var shouldInheritColor = hasDefinedColor || shouldInheritColorFromTextElement;
    return {
        _color: !shouldInheritColor && "grey",
        _size: defaultIconSize,
    };
};
exports.getIconDefaultProps = getIconDefaultProps;
var getTextDefaultProps = function (props) {
    var textElement = react_1.Children.only(props.children);
    if (!textElement) {
        return {};
    }
    var isBlock = !textElement.props._inline;
    var display = isBlock ? "flex" : undefined;
    var alignItems = isBlock ? "center" : undefined;
    return { _display: display, _alignItems: alignItems };
};
exports.getTextDefaultProps = getTextDefaultProps;
