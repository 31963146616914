"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.insetMixin = exports.inset = exports.insetStretched = exports.insetSquished = exports.insetMixinPropsType = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.insetMixinPropsType = new Props_1.PropsType({
    _inset: true,
    _insetSquished: true,
    _insetStretched: true,
});
var insetSquished = function (v) {
    var x = (0, formatters_1.px)(typeof v === "number" ? v : variables_1.spacing.insetSquish[v].x);
    var y = (0, formatters_1.px)(typeof v === "number" ? v : variables_1.spacing.insetSquish[v].y);
    return "padding: ".concat(y, " ").concat(x, ";");
};
exports.insetSquished = insetSquished;
var insetStretched = function (v) {
    var x = (0, formatters_1.px)(typeof v === "number" ? v : variables_1.spacing.insetStretch[v].x);
    var y = (0, formatters_1.px)(typeof v === "number" ? v : variables_1.spacing.insetStretch[v].y);
    return "padding: ".concat(y, " ").concat(x, ";");
};
exports.insetStretched = insetStretched;
var inset = function (v) {
    var pad = (0, formatters_1.px)(typeof v === "number" ? v : variables_1.spacing.inset[v]);
    return "padding: ".concat(pad, ";");
};
exports.inset = inset;
exports.insetMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_inset", exports.inset), (0, breakpointProp_1.breakpointProp)("_insetSquished", exports.insetSquished), (0, breakpointProp_1.breakpointProp)("_insetStretched", exports.insetStretched));
var templateObject_1;
