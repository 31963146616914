"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var use_callback_ref_1 = require("use-callback-ref");
var useCombinedRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    /**
     * Updates every ref, if combined ref changes see:
     * https://dev.to/thekashey/the-same-useref-but-it-will-callback-8bo
     */
    return (0, use_callback_ref_1.useCallbackRef)(null, function (newCurrent) {
        refs.forEach(function (ref) {
            if (!ref) {
                return;
            }
            if (typeof ref === "function") {
                ref(newCurrent);
            }
            else if (ref.current === null) {
                ref.current = newCurrent;
            }
        });
    });
};
exports.default = useCombinedRefs;
