"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fonts = void 0;
var styled_components_1 = require("styled-components");
var assets_1 = require("../tokens/ts/assets");
exports.Fonts = (0, styled_components_1.createGlobalStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @font-face {\n        font-family: \"Regular\";\n        src: url(data:font/ttf;base64,", ");\n        font-weight: normal;\n        font-style: normal;\n    }\n\n    @font-face {\n        font-family: \"Regular\";\n        src: url(data:font/ttf;base64,", ");\n        font-weight: bold;\n    }\n\n    @font-face {\n        font-family: \"Mono\";\n        src: url(data:font/ttf;base64,", ");\n    }\n\n    body {\n        font-family: \"Regular\", sans-serif;\n        letter-spacing: 0.2px;\n    }\n\n    h1,h2,h3,h4,h5,h6,\n    input,\n    input:-webkit-autofill::first-line,\n    input:-webkit-autofill {\n      font-family: inherit;\n    }\n"], ["\n    @font-face {\n        font-family: \"Regular\";\n        src: url(data:font/ttf;base64,", ");\n        font-weight: normal;\n        font-style: normal;\n    }\n\n    @font-face {\n        font-family: \"Regular\";\n        src: url(data:font/ttf;base64,", ");\n        font-weight: bold;\n    }\n\n    @font-face {\n        font-family: \"Mono\";\n        src: url(data:font/ttf;base64,", ");\n    }\n\n    body {\n        font-family: \"Regular\", sans-serif;\n        letter-spacing: 0.2px;\n    }\n\n    h1,h2,h3,h4,h5,h6,\n    input,\n    input:-webkit-autofill::first-line,\n    input:-webkit-autofill {\n      font-family: inherit;\n    }\n"])), assets_1.asset.font.brand.ttf.default.regular, assets_1.asset.font.brand.ttf.default.bold, assets_1.asset.font.brand.ttf.mono.regular);
exports.default = exports.Fonts;
var templateObject_1;
