"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fonts = void 0;
var styled_components_1 = require("styled-components");
var assets_1 = require("../tokens/ts/assets");
var variables_1 = require("../tokens/ts/variables");
var fontStyle = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: \"Regular\", sans-serif;\n  font-weight: 300;\n  font-size: ", ";\n"], ["\n  font-family: \"Regular\", sans-serif;\n  font-weight: 300;\n  font-size: ", ";\n"])), variables_1.size.font.text);
exports.Fonts = (0, styled_components_1.createGlobalStyle)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: 300;\n      font-style: normal;\n    }\n\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: normal;\n      font-style: normal;\n    }\n\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: bold;\n    }\n\n    @font-face {\n      font-family: \"Mono\";\n      src: url(data:font/ttf;base64,", ");\n    }\n\n    body {\n      ", ";\n    }\n\n    input:-webkit-autofill::first-line,\n    input:-webkit-autofill {\n      ", ";\n    }\n"], ["\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: 300;\n      font-style: normal;\n    }\n\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: normal;\n      font-style: normal;\n    }\n\n    @font-face {\n      font-family: \"Regular\";\n      src: url(data:font/woff;base64,", ");\n      font-weight: bold;\n    }\n\n    @font-face {\n      font-family: \"Mono\";\n      src: url(data:font/ttf;base64,", ");\n    }\n\n    body {\n      ", ";\n    }\n\n    input:-webkit-autofill::first-line,\n    input:-webkit-autofill {\n      ", ";\n    }\n"])), assets_1.asset.font.base.Frutiger.woff.light, assets_1.asset.font.base.Frutiger.woff.regular, assets_1.asset.font.base.Frutiger.woff.bold, assets_1.asset.font.brand.ttf.mono.regular, fontStyle, fontStyle);
exports.default = exports.Fonts;
var templateObject_1, templateObject_2;
