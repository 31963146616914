"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFindPageValue = void 0;
var useBreadcrumb_1 = require("./useBreadcrumb");
/** Walks up the breadcrumb to find a page value */
var useFindPageValue = function (key) {
    var _a;
    var breadcrumb = (0, useBreadcrumb_1.useBreadcrumb)();
    // walk up the breadcrumb to find the value
    return (_a = breadcrumb
        .findParentBreadcrumb(function (page) { return key in page && page[key] !== undefined; }, true)) === null || _a === void 0 ? void 0 : _a.getTopPage()[key];
};
exports.useFindPageValue = useFindPageValue;
exports.default = exports.useFindPageValue;
