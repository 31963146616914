"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLightOrDark = void 0;
var isLightOrDark = function (color) {
    var red = color.red, blue = color.blue, green = color.green;
    // see http://alienryderflex.com/hsp.html
    var hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));
    return hsp > 127.5 ? "light" : "dark";
};
exports.isLightOrDark = isLightOrDark;
exports.default = exports.isLightOrDark;
