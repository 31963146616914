"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDesignTokenValue = void 0;
var getObjectValue_1 = require("@mittwald/flow-lib/dist/object/getObjectValue");
var getDesignTokenValue = function (path, object) {
    if (typeof path !== "string") {
        return;
    }
    var value = (0, getObjectValue_1.getObjectValue)(path, object, {
        delimiter: "-",
        defaultValueObjectKey: "default",
    });
    if (typeof value === "string") {
        return value;
    }
};
exports.getDesignTokenValue = getDesignTokenValue;
exports.default = exports.getDesignTokenValue;
