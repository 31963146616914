"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusTypeIcons = exports.getStatusType = exports.userInteractionStatusPropsType = exports.userInteractionStatusTypes = exports.userInteractionStatusProps = exports.statusTypes = exports.statusTypeProps = void 0;
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var faCheckCircle_1 = require("@fortawesome/pro-regular-svg-icons/faCheckCircle");
var faInfoCircle_1 = require("@fortawesome/pro-regular-svg-icons/faInfoCircle");
var Props_1 = require("./Props");
exports.statusTypeProps = {
    error: true,
    warning: true,
    info: true,
    success: true,
};
exports.statusTypes = Object.keys(exports.statusTypeProps);
exports.userInteractionStatusProps = {
    destructive: true,
    warning: true,
    info: true,
    cancel: true,
    ok: true,
};
exports.userInteractionStatusTypes = Object.keys(exports.userInteractionStatusProps);
exports.userInteractionStatusPropsType = new Props_1.PropsType({
    warning: true,
    info: true,
    ok: true,
    cancel: true,
    destructive: true,
});
var getStatusType = function (error, warning, success) {
    return error ? "error" : warning ? "warning" : success ? "success" : "info";
};
exports.getStatusType = getStatusType;
exports.statusTypeIcons = {
    error: pro_regular_svg_icons_1.faExclamationCircle,
    warning: pro_regular_svg_icons_1.faExclamationCircle,
    success: faCheckCircle_1.faCheckCircle,
    info: faInfoCircle_1.faInfoCircle,
};
