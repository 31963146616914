"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsActiveRoute = void 0;
var react_router_dom_1 = require("react-router-dom");
/**
 * Checks if a give URL is active
 *
 * @param url Url to check against the current url
 * @param matchStrict Check strict and ignore partial paths eg => /foo/bar
 *   ignores /foo to be considered as active too
 */
var useIsActiveRoute = function (url, matchStrict) {
    if (matchStrict === void 0) { matchStrict = false; }
    return !!(0, react_router_dom_1.useMatch)({ end: matchStrict, path: url });
};
exports.useIsActiveRoute = useIsActiveRoute;
