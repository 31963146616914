"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.marginMixin = exports.marginMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.marginMixinPropsType = new Props_1.PropsType({
    _m: true,
    _mb: true,
    _ml: true,
    _mr: true,
    _mt: true,
    _mx: true,
    _my: true,
    _inlineGap: true,
    _stackGap: true,
});
exports.marginMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_m", function (v) { return "margin: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_mx", function (v) { return "margin: 0 ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_my", function (v) { return "margin: ".concat((0, formatters_1.spacingPx)(v), " 0"); }), (0, breakpointProp_1.breakpointProp)("_mt", function (v) { return "margin-top: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_mb", function (v) { return "margin-bottom: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_stackGap", function (v) { return "margin-bottom: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_inlineGap", function (v) { return "margin-right: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_inlineGap", function (v) {
    return v ? "display: inline-block" : undefined;
}), (0, breakpointProp_1.breakpointProp)("_ml", function (v) { return "margin-left: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_mr", function (v) { return "margin-right: ".concat((0, formatters_1.spacingPx)(v)); }));
exports.default = exports.marginMixin;
var templateObject_1;
