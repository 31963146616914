"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconSizeMixin = exports.calculateIconSize = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
var calculateIconSize = function (v) {
    var iconSize = 1;
    if (typeof v === "number") {
        iconSize = v;
    }
    if (typeof v === "string") {
        iconSize = parseInt(variables_1.size.icon[v]);
    }
    return iconSize;
};
exports.calculateIconSize = calculateIconSize;
exports.iconSizeMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_size", function (v) { return "font-size: ".concat((0, formatters_1.rem)((0, exports.calculateIconSize)(v)), ";"); }));
exports.default = exports.iconSizeMixin;
var templateObject_1;
