"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexBoxItemMixin = exports.flexBoxItemMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.flexBoxItemMixinPropsType = new Props_1.PropsType({
    _flex: true,
    _flexBasis: true,
    _grow: true,
    _alignSelf: true,
    _noShrink: true,
    _shrink: true,
});
var flexBoxSizeValue = function (size) {
    if (typeof size === "number") {
        return size;
    }
    return size ? 1 : 0;
};
exports.flexBoxItemMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_flexBasis", function (v) { return "flex-basis: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_flex", function (v) { return "flex: ".concat(v); }), (0, breakpointProp_1.breakpointProp)("_alignSelf", function (v) { return "align-self: ".concat(v); }), (0, breakpointProp_1.breakpointProp)("_grow", function (v) { return "flex-grow: ".concat(flexBoxSizeValue(v)); }), (0, breakpointProp_1.breakpointProp)("_shrink", function (v) { return "flex-shrink: ".concat(flexBoxSizeValue(v)); }), (0, breakpointProp_1.breakpointProp)("_noShrink", function (v) {
    return flexBoxSizeValue(v) ? "flex-shrink: 0" : undefined;
}));
exports.default = exports.flexBoxItemMixin;
var templateObject_1;
