"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitPortalContainer = void 0;
var react_1 = require("react");
var config_1 = require("../config");
var portalContainerElement_1 = require("../portalContainerElement");
var useInitPortalContainer = function () {
    var initialized = (0, react_1.useRef)(false);
    if (!initialized.current) {
        var containerFound = document.getElementById(config_1.portalContainerId) !== null;
        if (!containerFound) {
            document.body.appendChild(portalContainerElement_1.portalContainerElement);
        }
    }
};
exports.useInitPortalContainer = useInitPortalContainer;
exports.default = exports.useInitPortalContainer;
