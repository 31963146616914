import { types } from "mobx-state-tree";

export const model = types
    .model({
        editorContent: types.string,
        projectId: types.maybe(types.string),
    })
    .actions((self) => ({
        setEditorContent: (content: string) => {
            self.editorContent = content;
        },
    }));

export const varnishStore = model.create({
    editorContent: "",
});

export default varnishStore;
