"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonBaseStyle = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
var general = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  // required for rendering as link\n  display: inline-grid;\n  border: none;\n  border-radius: ", ";\n  outline: none;\n  overflow: hidden;\n  font-size: ", ";\n  font-weight: bold;\n  line-height: ", ";\n  cursor: pointer;\n  width: fit-content;\n\n  transition: background ", "ms ease,\n    color ", "ms ease;\n\n  &:disabled {\n    cursor: not-allowed;\n  }\n\n  color: ", ";\n  background: ", ";\n"], ["\n  // required for rendering as link\n  display: inline-grid;\n  border: none;\n  border-radius: ", ";\n  outline: none;\n  overflow: hidden;\n  font-size: ", ";\n  font-weight: bold;\n  line-height: ", ";\n  cursor: pointer;\n  width: fit-content;\n\n  transition: background ", "ms ease,\n    color ", "ms ease;\n\n  &:disabled {\n    cursor: not-allowed;\n  }\n\n  color: ", ";\n  background: ", ";\n"])), variables_1.size.borderRadius.button, variables_1.size.font.button, variables_1.size.lineHeight.text.default, variables_1.duration.animation.fast, variables_1.duration.animation.fast, variables_1.color.font.text.default, variables_1.color.background.default);
var info = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"])), variables_1.color.font.button.continue.default, variables_1.color.background.button.continue.default, variables_1.color.font.button.continue.hover, variables_1.color.background.button.continue.hover, variables_1.color.font.button.continue.active, variables_1.color.background.button.continue.active, variables_1.color.font.button.continue.disabled, variables_1.color.background.button.continue.disabled);
var ok = (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"])), variables_1.color.font.button.complete.default, variables_1.color.background.button.complete.default, variables_1.color.font.button.complete.hover, variables_1.color.background.button.complete.hover, variables_1.color.font.button.complete.active, variables_1.color.background.button.complete.active, variables_1.color.font.button.complete.disabled, variables_1.color.background.button.complete.disabled);
var destructive = (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"])), variables_1.color.font.button.destructive.default, variables_1.color.background.button.destructive.default, variables_1.color.font.button.destructive.hover, variables_1.color.background.button.destructive.hover, variables_1.color.font.button.destructive.active, variables_1.color.background.button.destructive.active, variables_1.color.font.button.destructive.disabled, variables_1.color.background.button.destructive.disabled);
var cancel = (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n\n  &.hover,\n  &:hover {\n    color: ", ";\n    background: ", ";\n  }\n\n  &.active,\n  &:active {\n    color: ", ";\n    background: ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n    background: ", ";\n  }\n"])), variables_1.color.font.button.link.default, variables_1.color.base.grey.$100, variables_1.color.font.button.link.default, variables_1.color.base.grey.$200, variables_1.color.font.button.link.active, variables_1.color.base.grey.$200, variables_1.color.font.button.link.disabled, variables_1.color.base.grey.$50);
var iconOnly = (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  &.cancel {\n    background: none;\n    color: ", ";\n\n    &.hover,\n    &:hover {\n      background: ", ";\n    }\n\n    &.active,\n    &:active {\n      background: ", ";\n    }\n\n    &:disabled {\n      color: ", ";\n      background: none;\n    }\n  }\n"], ["\n  &.cancel {\n    background: none;\n    color: ", ";\n\n    &.hover,\n    &:hover {\n      background: ", ";\n    }\n\n    &.active,\n    &:active {\n      background: ", ";\n    }\n\n    &:disabled {\n      color: ", ";\n      background: none;\n    }\n  }\n"])), variables_1.color.font.button.link.default, variables_1.color.brand.primary.lighter, variables_1.color.brand.primary.lighter, variables_1.color.font.button.link.disabled);
exports.buttonBaseStyle = (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n\n  &.info {\n    ", ";\n  }\n\n  &.ok {\n    ", ";\n  }\n\n  &.destructive {\n    ", ";\n  }\n\n  &.cancel {\n    ", ";\n  }\n\n  &.iconOnly {\n    ", ";\n  }\n"], ["\n  ", ";\n\n  &.info {\n    ", ";\n  }\n\n  &.ok {\n    ", ";\n  }\n\n  &.destructive {\n    ", ";\n  }\n\n  &.cancel {\n    ", ";\n  }\n\n  &.iconOnly {\n    ", ";\n  }\n"])), general, info, ok, destructive, cancel, iconOnly);
exports.default = exports.buttonBaseStyle;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
