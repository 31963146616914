"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHeaders = void 0;
function isHeaderObjects(headers) {
    return !!headers && "forEach" in headers;
}
exports.mapHeaders = (headers) => {
    const result = {};
    if (!isHeaderObjects(headers)) {
        return headers || {};
    }
    headers.forEach((val, key) => {
        result[key] = val;
    });
    return result;
};
