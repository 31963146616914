"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsStore = exports.model = exports.defaultLanguage = void 0;
var mobx_state_tree_1 = require("mobx-state-tree");
var types_1 = require("../i18n/types");
var intlUtils_1 = require("../lib/intlUtils");
exports.defaultLanguage = "de";
exports.model = mobx_state_tree_1.types
    .model({
    language: mobx_state_tree_1.types.enumeration([
        "de",
        "en",
        "browserLanguage",
    ]),
    compact: mobx_state_tree_1.types.model({
        enabled: mobx_state_tree_1.types.maybeNull(mobx_state_tree_1.types.boolean),
        breakpointValue: mobx_state_tree_1.types.maybeNull(mobx_state_tree_1.types.number),
    }),
})
    .actions(function (self) { return ({
    setLanguage: function (lang) {
        self.language = lang;
    },
    toggleCompactMode: function () {
        self.compact.enabled = !self.compact.enabled;
    },
    enableCompactMode: function () { return (self.compact.enabled = true); },
    disableCompactMode: function () { return (self.compact.enabled = false); },
    detectCompactMode: function () {
        var breakPointValue = self.compact.breakpointValue;
        if (breakPointValue) {
            self.compact.enabled = window.innerWidth < breakPointValue;
        }
    },
}); })
    .actions(function (self) { return ({
    initSettings: function (breakpoint) {
        self.compact.breakpointValue = breakpoint;
        self.detectCompactMode();
    },
}); })
    .views(function (self) { return ({
    /** This will return the current language that is like to be used. */
    getLanguage: function () {
        return self.language === "browserLanguage"
            ? intlUtils_1.browserLanguageCode
            : self.language;
    },
    /**
     * Will return the resolved language. Either the fixed language or if
     * browser language is used the extracted language key. The returned
     * language key is available in the i18n translate object.
     */
    getResolvedLanguage: function () {
        if (self.language === "browserLanguage") {
            if (!(0, types_1.isSupportedLanguage)(intlUtils_1.browserLanguageCode)) {
                return exports.defaultLanguage;
            }
            return intlUtils_1.browserLanguageCode;
        }
        return self.language;
    },
}); });
exports.settingsStore = exports.model.create({
    language: exports.defaultLanguage,
    compact: {
        enabled: null,
        breakpointValue: null,
    },
});
exports.default = exports.settingsStore;
