"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFallbackMap = void 0;
var react_1 = require("react");
var isFallbackMap = function (fallback) {
    if (!fallback) {
        return false;
    }
    if ((0, react_1.isValidElement)(fallback)) {
        return false;
    }
    if (typeof fallback === "function") {
        return false;
    }
    return typeof fallback === "object";
};
exports.isFallbackMap = isFallbackMap;
