"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.visibilityMixin = exports.visibilityMixinPropsType = void 0;
var utility_1 = require("@mittwald/flow-styles/dist/mixins/utility");
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
exports.visibilityMixinPropsType = new Props_1.PropsType({
    _display: true,
    _hideIfEmpty: true,
    _interactionBlocked: true,
    _visible: true,
    _invisible: true,
    _visuallyHidden: true,
});
exports.visibilityMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_visible", function (v) { return "visibility: ".concat(v ? "visible" : "hidden"); }), (0, breakpointProp_1.breakpointProp)("_invisible", function (v) { return "visibility: ".concat(v ? "hidden" : "visible"); }), (0, breakpointProp_1.breakpointProp)("_display", function (v) { return "display: ".concat(v); }), (0, breakpointProp_1.breakpointProp)("_visuallyHidden", function (v) { return "".concat(v ? utility_1.visuallyHidden : undefined); }), (0, breakpointProp_1.breakpointProp)("_interactionBlocked", function (v) {
    return v
        ? "\n        pointer-events: none;\n        & > * {\n            opacity: ".concat(variables_1.opacity.disabled, ";\n        }\n    ")
        : "";
}), (0, breakpointProp_1.breakpointProp)("_hideIfEmpty", function (v) {
    return v
        ? "\n        &:empty {\n            display: none;\n        }\n    "
        : "";
}));
exports.default = exports.visibilityMixin;
var templateObject_1;
