"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHideLoadingIcon = void 0;
var react_1 = require("react");
var loadingIconContainerSelector = ".App__Icon__Container";
var useHideLoadingIcon = function () {
    return (0, react_1.useEffect)(function () {
        var loadingIconContainer = document.querySelector(loadingIconContainerSelector);
        if (!(loadingIconContainer instanceof HTMLDivElement)) {
            return;
        }
        loadingIconContainer.className +=
            " App__Icon__Container__FadeOut  App__Icon__FlyAway";
        var timeout = window.setTimeout(function () {
            loadingIconContainer.remove();
        }, 800);
        return function () {
            window.clearTimeout(timeout);
        };
    }, [loadingIconContainerSelector]);
};
exports.useHideLoadingIcon = useHideLoadingIcon;
exports.default = exports.useHideLoadingIcon;
