"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globals = void 0;
const defaultSleeper = () => new Promise((res) => setTimeout(() => res(), Math.random() * 500 + 200));
exports.globals = {
    mockRequestSleeper: defaultSleeper,
    setMockRequestSleeper: (sleeper) => {
        exports.globals.mockRequestSleeper = sleeper;
    },
    resetMockRequestSleeper: () => {
        exports.globals.mockRequestSleeper = defaultSleeper;
    },
};
exports.default = exports.globals;
