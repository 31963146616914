"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceLoadingErrorBoundary = void 0;
var react_1 = __importStar(require("react"));
var ResourceLoadingError_1 = require("../ResourceLoadingError");
var InternalErrorObserverView_1 = require("./components/InternalErrorObserverView");
var ResourceLoadingErrorBoundary = /** @class */ (function (_super) {
    __extends(ResourceLoadingErrorBoundary, _super);
    function ResourceLoadingErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    ResourceLoadingErrorBoundary.getDerivedStateFromError = function (error) {
        return { error: ResourceLoadingError_1.ResourceLoadingError.fromError(error) };
    };
    ResourceLoadingErrorBoundary.prototype.componentDidCatch = function () { };
    ResourceLoadingErrorBoundary.prototype.handleResolved = function () {
        this.setState({ error: undefined });
    };
    ResourceLoadingErrorBoundary.prototype.render = function () {
        var _this = this;
        var error = this.state.error;
        return (react_1.default.createElement(InternalErrorObserverView_1.InternalErrorObserverView, __assign({}, this.props, { error: error, onResolved: function () { return _this.handleResolved(); } }), this.props.children));
    };
    return ResourceLoadingErrorBoundary;
}(react_1.Component));
exports.ResourceLoadingErrorBoundary = ResourceLoadingErrorBoundary;
exports.default = ResourceLoadingErrorBoundary;
