"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceState = void 0;
var ResourceState;
(function (ResourceState) {
    ResourceState["INITIALIZED"] = "initialized";
    ResourceState["LOADING"] = "loading";
    ResourceState["LOADED"] = "loaded";
    ResourceState["ERROR"] = "error";
    ResourceState["EXPIRED"] = "expired";
})(ResourceState = exports.ResourceState || (exports.ResourceState = {}));
