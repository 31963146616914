"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGetApiOperationResource = void 0;
var Resource_1 = require("../Resource");
var ApiOperationResource_1 = require("./ApiOperationResource");
var apiOperationResourceStore_1 = require("./apiOperationResourceStore");
var nullResource = new Resource_1.Resource(function () { return Promise.resolve(null); });
var createGetApiOperationResource = function (operationDescriptor, requestFn) {
    return function (requestParams) {
        if (requestParams === null) {
            return nullResource;
        }
        var newResource = new ApiOperationResource_1.ApiOperationResource(operationDescriptor, requestFn, requestParams);
        return apiOperationResourceStore_1.apiOperationResourceStore.getOrSet([operationDescriptor, requestParams], newResource);
    };
};
exports.createGetApiOperationResource = createGetApiOperationResource;
exports.default = exports.createGetApiOperationResource;
