"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropsType = void 0;
var lib_1 = require("./lib");
var PropsType = /** @class */ (function () {
    function PropsType(keys) {
        var _this = this;
        this.shouldForwardProp = function (prop, ignoredDefaultValidatorFn) {
            return !_this.keyList.includes(prop);
        };
        this.keys = keys;
        this.keyList = Object.keys(keys);
    }
    PropsType.prototype.extend = function (otherProps) {
        return new PropsType(__assign(__assign({}, this.keys), otherProps.keys));
    };
    PropsType.prototype.complete = function (completeKeys) {
        return new PropsType(__assign(__assign({}, this.keys), completeKeys));
    };
    PropsType.prototype.pickFrom = function (props) {
        var e_1, _a;
        var knownProps = {};
        if (typeof props !== "object") {
            throw new Error("PropsType.pickFrom() required a valid object");
        }
        try {
            for (var _b = __values(Object.entries(props)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), prop = _d[0], value = _d[1];
                if ((0, lib_1.isGlobalAvailableProp)(prop) || this.keyList.includes(prop)) {
                    knownProps[prop] = value;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return knownProps;
    };
    PropsType.prototype.omitFrom = function (props) {
        var e_2, _a;
        var knownProps = {};
        if (typeof props !== "object") {
            throw new Error("PropsType.omitFrom() required valid object");
        }
        try {
            for (var _b = __values(Object.entries(props)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), prop = _d[0], value = _d[1];
                if (!this.keyList.includes(prop)) {
                    knownProps[prop] = value;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return knownProps;
    };
    PropsType.prototype.exclude = function (props) {
        var e_3, _a;
        var withoutExcludedKeys = __assign({}, this.keys);
        var propNamesToExclude = props instanceof PropsType
            ? props.keyList
            : Array.isArray(props)
                ? props
                : Object.keys(props);
        try {
            for (var propNamesToExclude_1 = __values(propNamesToExclude), propNamesToExclude_1_1 = propNamesToExclude_1.next(); !propNamesToExclude_1_1.done; propNamesToExclude_1_1 = propNamesToExclude_1.next()) {
                var prop = propNamesToExclude_1_1.value;
                if (prop in withoutExcludedKeys) {
                    delete withoutExcludedKeys[prop];
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (propNamesToExclude_1_1 && !propNamesToExclude_1_1.done && (_a = propNamesToExclude_1.return)) _a.call(propNamesToExclude_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return new PropsType(withoutExcludedKeys);
    };
    return PropsType;
}());
exports.PropsType = PropsType;
exports.default = PropsType;
