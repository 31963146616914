import { asset } from "@mittwald/flow-styles/dist/tokens/ts/assets";
import makeConfig from "@mittwald/flow-lib/dist/dev/makeConfig";

export const config = makeConfig({
    sentryDsn: {
        start: "${REACT_APP_SENTRY_DSN}",
        defaults: undefined,
    },
    appIcon: {
        build: "${REACT_APP_APP_ICON}",
        defaults: asset.icon.brand.appIcon,
    },
    signupUri: {
        start: "${REACT_APP_SIGNUP_URI}",
        defaults: {
            dev: "https://api-public.dev.mittwald.systems/v2/signup",
            prod: "https://signup.prod.mittwald.systems",
        },
    },
    environment: {
        defaults: {
            local: "local",
            dev: "development",
            prod: "production",
        },
    },
    apiUri: {
        start: "${REACT_APP_API_URI}",
        defaults: {
            dev: "https://api-public.prod.mittwald.systems",
            prod: "https://api-public.prod.mittwald.systems",
        },
    },
    legacyApiUri: {
        start: "${REACT_APP_LEGACY_API_URI}",
        defaults: {
            dev: "https://api.mittwald.de/v1",
            prod: "https://api.mittwald.de/v1",
        },
    },
    identityApiUri: {
        start: "${REACT_APP_IDENTITY_API_URI}",
        defaults: {
            dev: "https://identity.dev.mittwald.systems",
            prod: "https://identity.prod.mittwald.systems",
        },
    },
    gitlabApiUri: {
        start: "${REACT_APP_GITLAB_API_URI}",
        defaults: {
            dev: "https://gitlab.mittwald.it/api/v4",
            prod: "https://gitlab.mittwald.it/api/v4",
        },
    },
    shareSecretApiUri: {
        start: "${REACT_APP_SHARE_SECRET_API_URI}",
        defaults: {
            dev: "https://share-secret.dev.mittwald.systems",
            prod: "https://share-secret.prod.mittwald.systems",
        },
    },
    cookiePrefix: {
        start: "${REACT_APP_COOKIE_PREFIX}",
        defaults: "mw-dev",
    },
    version: {
        build: "${REACT_APP_VERSION}",
        defaults: "local-dev",
    },
});
