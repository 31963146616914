"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildId = exports.isTaggedResource = void 0;
var object_code_1 = require("object-code");
var Resource_1 = require("./Resource");
var isTaggedResource = function (resource) {
    return resource instanceof Resource_1.Resource && "tags" in resource;
};
exports.isTaggedResource = isTaggedResource;
var buildId = function (idSource) {
    if (idSource === null || idSource === undefined) {
        idSource = "@@null@@";
    }
    return (0, object_code_1.hash)({ id: idSource });
};
exports.buildId = buildId;
