"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendTranslationKey = exports.joinTranslationKeys = void 0;
var joinTranslationKeys = function () {
    var parts = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        parts[_i] = arguments[_i];
    }
    return parts.filter(function (p) { return p !== "" && p !== undefined && p !== false; }).join(".");
};
exports.joinTranslationKeys = joinTranslationKeys;
var appendTranslationKey = function (definition, suffix) {
    if (typeof definition === "string") {
        return (0, exports.joinTranslationKeys)(definition, suffix);
    }
    if (typeof definition === "object") {
        if ("id" in definition) {
            return __assign(__assign({}, definition), { id: (0, exports.joinTranslationKeys)(definition.id, suffix) });
        }
    }
    return definition;
};
exports.appendTranslationKey = appendTranslationKey;
