"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rem = exports.remNumber = exports.baseFontSize = exports.pxNumber = exports.px = exports.spacingPx = void 0;
var variables_1 = require("@mittwald/flow-styles/dist/tokens/ts/variables");
var Spacing_1 = require("./Spacing");
var spacingPx = function (size) {
    var _a;
    if (typeof size === "number") {
        return "".concat(size, "px");
    }
    if ((0, Spacing_1.isRawSpacing)(size)) {
        return size.raw;
    }
    if (size === "auto") {
        return size;
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return (_a = variables_1.spacing.base[size]) !== null && _a !== void 0 ? _a : "0px";
};
exports.spacingPx = spacingPx;
var px = function (size, negate) {
    if (negate === void 0) { negate = false; }
    var sizeString = typeof size === "number" ? "".concat(size, "px") : size;
    return negate ? "-".concat(sizeString) : sizeString;
};
exports.px = px;
var pxNumber = function (size) {
    return typeof size === "number" ? size : parseInt(size);
};
exports.pxNumber = pxNumber;
exports.baseFontSize = (0, exports.pxNumber)(variables_1.size.font.text);
var remNumber = function (size) { return (0, exports.pxNumber)(size) / exports.baseFontSize; };
exports.remNumber = remNumber;
var rem = function (size) { return "".concat((0, exports.remNumber)(size), "rem"); };
exports.rem = rem;
