"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOptionalPathParams = void 0;
var locationMockingValueContext_1 = require("./locationMockingValueContext");
var useBreadcrumb_1 = require("./useBreadcrumb");
var useOptionalPathParams = function () {
    var breadcrumb = (0, useBreadcrumb_1.useBreadcrumb)();
    var mockValues = (0, locationMockingValueContext_1.useLocationMockingValueContext)();
    return (mockValues === null || mockValues === void 0 ? void 0 : mockValues.pathParams) ? mockValues.pathParams : breadcrumb.pathParams;
};
exports.useOptionalPathParams = useOptionalPathParams;
exports.default = exports.useOptionalPathParams;
