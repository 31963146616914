"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
var pathParams_1 = require("../routing/pathParams");
var Page = /** @class */ (function () {
    function Page(id) {
        if (id === void 0) { id = "root"; }
        this.props = {};
        this.views = {};
        this.isOverlay = false;
        this.isDestructive = false;
        this.isExternalLink = false;
        this.pages = [];
        this.level = 0;
        this.id = id;
    }
    Page.fromData = function (id, data) {
        var page = new Page(id);
        var views = data.views, accessCheck = data.accessCheck, _a = data.hooks, hooks = _a === void 0 ? {} : _a, pathSegment = data.pathSegment, props = data.props, renderer = data.renderer, icon = data.icon, _b = data.isOverlay, isOverlay = _b === void 0 ? false : _b, _c = data.isDestructive, isDestructive = _c === void 0 ? false : _c, linkId = data.linkId, _d = data.isExternalLink, isExternalLink = _d === void 0 ? false : _d, sector = data.sector;
        var useCheckState = hooks.useCheckState;
        page.isOverlay = isOverlay;
        page.isDestructive = isDestructive;
        page.isExternalLink = isExternalLink;
        page.sector = sector;
        if (accessCheck) {
            page.setCheckPageAccess(accessCheck);
        }
        if (icon) {
            page.icon = icon;
        }
        if (views) {
            Object.entries(views).forEach(function (_a) {
                var _b = __read(_a, 2), area = _b[0], view = _b[1];
                return (page.views[area] = view);
            });
        }
        if (accessCheck !== undefined) {
            page.setCheckPageAccess(accessCheck);
        }
        if (useCheckState) {
            page.setCheckState(useCheckState);
        }
        if (props && typeof props === "object") {
            Object.entries(props).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return page.setProp(key, value);
            });
        }
        if (pathSegment) {
            page.setPathSegment(pathSegment);
        }
        if (renderer) {
            page.setRenderer(renderer);
        }
        if (linkId) {
            page.setLinkId(linkId);
        }
        return page;
    };
    Page.prototype.setPathSegment = function (segment) {
        this.pathSegment = segment;
        return this;
    };
    Page.prototype.getPathSegment = function () {
        return this.pathSegment || this.id;
    };
    Page.prototype.addSubPage = function (page) {
        this.pages.push(page);
        page.level = this.level + 1;
        return this;
    };
    Page.prototype.addSubPages = function (pages) {
        var _this = this;
        pages.forEach(function (p) { return _this.addSubPage(p); });
        return this;
    };
    Page.prototype.hasPathParams = function () {
        return !!this.pathSegment && this.pathSegment.includes(":");
    };
    Page.prototype.setView = function (viewName, view) {
        this.views[viewName] = view;
        return this;
    };
    Page.prototype.setRenderer = function (renderer) {
        this.renderer = renderer;
        return this;
    };
    Page.prototype.setCheckPageAccess = function (check) {
        this.accessCheck = {
            key: this.id,
            check: check,
        };
        return this;
    };
    Page.prototype.setCheckState = function (check) {
        this.checkState = check;
        return this;
    };
    Page.prototype.setProp = function (name, value) {
        this.props[name] = value;
        return this;
    };
    Page.prototype.settUuidPathParam = function (paramName) {
        this.setPathSegment((0, pathParams_1.uuidPathParam)(paramName));
        return this;
    };
    Page.prototype.setLinkId = function (linkId) {
        this.linkId = linkId;
        return this;
    };
    return Page;
}());
exports.Page = Page;
