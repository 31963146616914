"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFieldError = void 0;
var react_1 = require("react");
var useTranslation_1 = require("../../../hooks/useTranslation");
var translation_1 = require("../../../lib/translation");
var Form_1 = require("../../Form");
var useFieldErrorType_1 = require("./useFieldErrorType");
var useFieldError = function (fieldName, options) {
    var _a;
    if (options === void 0) { options = {}; }
    var context = (0, Form_1.useOptionalFormContext)();
    var _b = options.errorPrefix, errorPrefix = _b === void 0 ? "form.validationErrors" : _b, store = options.store;
    if (!context) {
        return;
    }
    var fieldErrorParamsController = context.fieldErrorParamsController, formState = context.formState;
    var fieldErrorParams = fieldErrorParamsController.params;
    var errors = formState.errors;
    var fallback = options.fallback
        ? options.fallback
        : fieldName
            ? (_a = errors[fieldName]) === null || _a === void 0 ? void 0 : _a.message
            : undefined;
    var type = (0, useFieldErrorType_1.useFieldErrorType)(fieldName);
    var storedType = (0, react_1.useRef)(type);
    var translate = (0, useTranslation_1.useTranslation)({
        prefix: errorPrefix,
    });
    if (type) {
        storedType.current = type;
    }
    if (store) {
        type = storedType.current;
    }
    if (!type && !fallback) {
        return;
    }
    var translationValues = fieldName && fieldErrorParams && fieldName in fieldErrorParams
        ? fieldErrorParams[fieldName]
        : undefined;
    return translate({
        id: (0, translation_1.joinTranslationKeys)(fieldName, type),
        values: translationValues,
    }, {
        id: type,
        values: translationValues,
    }, fallback ? { text: fallback } : undefined);
};
exports.useFieldError = useFieldError;
