"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MissingTranslationError = void 0;
var reportingDataExtraction_1 = require("@mittwald/flow-lib/dist/errors/errorReporting/reportingDataExtraction");
var MissingTranslationError = /** @class */ (function (_super) {
    __extends(MissingTranslationError, _super);
    function MissingTranslationError(translationAttempts, currentLanguage) {
        var _this = _super.call(this, "Tried to translate following keys, but none of them could be translated: \n\n".concat(translationAttempts.join("\n"))) || this;
        _this.name = "MissingTranslationError";
        Object.setPrototypeOf(_this, MissingTranslationError.prototype);
        _this.translationAttempts = translationAttempts;
        _this.currentLanguage = currentLanguage;
        return _this;
    }
    return MissingTranslationError;
}(Error));
exports.MissingTranslationError = MissingTranslationError;
exports.default = MissingTranslationError;
(0, reportingDataExtraction_1.registerErrorReportingDataExtractionFn)(function (error) {
    if (error instanceof MissingTranslationError) {
        return {
            extra: {
                translationAttempts: error.translationAttempts.join(", "),
            },
            tags: {
                language: error.currentLanguage,
                mainTranslationKey: error.translationAttempts[0],
            },
        };
    }
});
