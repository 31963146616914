"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positionPropsMap = void 0;
var formatters_1 = require("../../mixins/formatters");
var config_1 = require("./config");
// Define values depending on the OffCanvas position
var positionPropsMap = function (motionValue) { return ({
    bottom: {
        animations: {
            visible: {
                y: 0,
                transition: config_1.transition,
            },
            hidden: function (size) {
                return {
                    y: (size === null || size === void 0 ? void 0 : size.height)
                        ? size.height + (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                        : "calc(100% + ".concat(config_1.canvasBoxShadowSize, "px)"),
                    transition: config_1.transition,
                };
            },
            swipedExit: function (size) { return ({
                y: (size === null || size === void 0 ? void 0 : size.height)
                    ? size.height + (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(100% + ".concat(config_1.canvasBoxShadowSize, "px)"),
            }); },
        },
        dragConstraints: {
            top: 0,
        },
        dragDirection: "y",
        canvasStyle: { minHeight: config_1.minCanvasSize, y: motionValue },
        curtainProps: { _flexDirection: "column", _justifyContent: "flex-end" },
    },
    top: {
        animations: {
            visible: {
                y: 0,
                transition: config_1.transition,
            },
            hidden: function (size) { return ({
                y: (size === null || size === void 0 ? void 0 : size.height)
                    ? -1 * size.height - (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(-100% - ".concat(config_1.canvasBoxShadowSize, "px)"),
                transition: config_1.transition,
            }); },
            swipedExit: function (size) { return ({
                y: (size === null || size === void 0 ? void 0 : size.height)
                    ? -1 * size.height - (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(-100% - ".concat(config_1.canvasBoxShadowSize, "px)"),
            }); },
        },
        dragConstraints: {
            bottom: 0,
        },
        dragDirection: "y",
        canvasStyle: { minHeight: config_1.minCanvasSize, y: motionValue },
        curtainProps: { _flexDirection: "column", _justifyContent: "flex-start" },
    },
    right: {
        animations: {
            visible: {
                x: 0,
                transition: config_1.transition,
            },
            hidden: function (size) { return ({
                x: (size === null || size === void 0 ? void 0 : size.width)
                    ? size.width + (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(100% + ".concat(config_1.canvasBoxShadowSize, "px)"),
                transition: config_1.transition,
            }); },
            swipedExit: function (size) { return ({
                x: (size === null || size === void 0 ? void 0 : size.width)
                    ? size.width + (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(100% + ".concat(config_1.canvasBoxShadowSize, "px)"),
            }); },
        },
        dragConstraints: {
            left: 0,
        },
        dragDirection: "x",
        canvasStyle: { minWidth: config_1.minCanvasSize, x: motionValue },
        curtainProps: { _flexDirection: "row", _justifyContent: "flex-end" },
    },
    left: {
        animations: {
            visible: {
                x: 0,
                transition: config_1.transition,
            },
            hidden: function (size) { return ({
                x: (size === null || size === void 0 ? void 0 : size.width)
                    ? -1 * size.width - (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(-100% - ".concat(config_1.canvasBoxShadowSize, "px)"),
                transition: config_1.transition,
            }); },
            swipedExit: function (size) { return ({
                x: (size === null || size === void 0 ? void 0 : size.width)
                    ? -1 * size.width - (0, formatters_1.pxNumber)(config_1.canvasBoxShadowSize)
                    : "calc(-100% - ".concat(config_1.canvasBoxShadowSize, "px)"),
            }); },
        },
        dragConstraints: {
            right: 0,
        },
        dragDirection: "x",
        canvasStyle: { minWidth: config_1.minCanvasSize, x: motionValue },
        curtainProps: { _flexDirection: "row", _justifyContent: "flex-start" },
    },
}); };
exports.positionPropsMap = positionPropsMap;
