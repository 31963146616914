"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.or = exports.and = exports.hasNoPathParams = exports.isNoToolSettingsPage = exports.isToolSettingsPage = exports.hasNoToolSettingsPage = exports.hasToolSettingsPage = exports.isNoCtaPage = exports.isCtaPage = exports.hasNoCtaPages = exports.hasCtaPages = exports.tabNavEnabled = exports.sideNavEnabled = exports.menuHidden = exports.menuVisible = exports.hasId = exports.hasMainContent = exports.hasMinLevel = exports.hasMaxLevel = exports.modal = exports.noModal = void 0;
var noModal = function (page) { return !page.isOverlay; };
exports.noModal = noModal;
var modal = function (page) { return page.isOverlay; };
exports.modal = modal;
var hasMaxLevel = function (maxLevel) {
    return function (page) {
        return page.level <= maxLevel;
    };
};
exports.hasMaxLevel = hasMaxLevel;
var hasMinLevel = function (minLevel) {
    return function (page) {
        return page.level >= minLevel;
    };
};
exports.hasMinLevel = hasMinLevel;
var hasMainContent = function (page) { return !!page.views["main"]; };
exports.hasMainContent = hasMainContent;
var hasId = function (id) {
    return function (page) {
        return page.linkId === id;
    };
};
exports.hasId = hasId;
var menuVisible = function (page) { return !page.props.hideInMenu; };
exports.menuVisible = menuVisible;
var menuHidden = function (page) { return page.props.hideInMenu; };
exports.menuHidden = menuHidden;
var sideNavEnabled = function (page) { return !!page.props.sideNav; };
exports.sideNavEnabled = sideNavEnabled;
var tabNavEnabled = function (page) { return !!page.props.tabNav; };
exports.tabNavEnabled = tabNavEnabled;
var hasCtaPages = function (page) { return !!page.props.ctaPages; };
exports.hasCtaPages = hasCtaPages;
var hasNoCtaPages = function (page, breadcrumb) {
    return !(0, exports.hasCtaPages)(page, breadcrumb);
};
exports.hasNoCtaPages = hasNoCtaPages;
var isCtaPage = function (page, breadcrumb) {
    var _a, _b;
    return !!page.linkId &&
        ((_b = (_a = breadcrumb
            .findParentBreadcrumb(exports.hasCtaPages, true)) === null || _a === void 0 ? void 0 : _a.getTopPage().props.ctaPages) === null || _b === void 0 ? void 0 : _b.includes(page.linkId));
};
exports.isCtaPage = isCtaPage;
var isNoCtaPage = function (page, breadcrumb) {
    return !(0, exports.isCtaPage)(page, breadcrumb);
};
exports.isNoCtaPage = isNoCtaPage;
var hasToolSettingsPage = function (page) {
    return page.props.toolSettingsPage;
};
exports.hasToolSettingsPage = hasToolSettingsPage;
var hasNoToolSettingsPage = function (page, breadcrumb) {
    return !(0, exports.hasToolSettingsPage)(page, breadcrumb);
};
exports.hasNoToolSettingsPage = hasNoToolSettingsPage;
var isToolSettingsPage = function (page, breadcrumb) {
    var _a;
    return ((_a = breadcrumb.findParentBreadcrumb(exports.hasToolSettingsPage, true)) === null || _a === void 0 ? void 0 : _a.getTopPage().props.toolSettingsPage) === page.linkId;
};
exports.isToolSettingsPage = isToolSettingsPage;
var isNoToolSettingsPage = function (page, breadcrumb) {
    return !(0, exports.isToolSettingsPage)(page, breadcrumb);
};
exports.isNoToolSettingsPage = isNoToolSettingsPage;
var hasNoPathParams = function (page) { return !page.hasPathParams(); };
exports.hasNoPathParams = hasNoPathParams;
var and = function () {
    var matchers = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        matchers[_i] = arguments[_i];
    }
    return function (page, breadcrumb) {
        return matchers.every(function (m) { return m === undefined || m(page, breadcrumb); });
    };
};
exports.and = and;
var or = function () {
    var matchers = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        matchers[_i] = arguments[_i];
    }
    return function (page, breadcrumb) {
        return matchers.some(function (m) { return m !== undefined && m(page, breadcrumb); });
    };
};
exports.or = or;
