"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlInspector = void 0;
var MissingPathParameterError_1 = require("@mittwald/flow-lib/dist/errors/MissingPathParameterError");
var MissingQueryParameterError_1 = require("@mittwald/flow-lib/dist/errors/MissingQueryParameterError");
var urlInspector = function (error, infos) {
    if (error instanceof MissingPathParameterError_1.MissingPathParameterError) {
        infos.infoTranslationKeys.push("url.missingPathParameter");
        infos.infoTranslationKeys.push("url.missingParameter");
        infos.details.push({
            subject: "url.missingPathParameter",
            value: error.paramName,
        });
    }
    else if (error instanceof MissingQueryParameterError_1.MissingQueryParameterError) {
        infos.infoTranslationKeys.push("url.missingQueryParameter");
        infos.infoTranslationKeys.push("url.missingParameter");
        infos.details.push({
            subject: "url.missingQueryParameter",
            value: error.paramName,
        });
    }
    else {
        return false;
    }
};
exports.urlInspector = urlInspector;
exports.default = exports.urlInspector;
