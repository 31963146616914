"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dimensionMixin = exports.dimensionMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.dimensionMixinPropsType = new Props_1.PropsType({
    _height: true,
    _width: true,
    _maxH: true,
    _maxW: true,
    _minH: true,
    _minW: true,
});
exports.dimensionMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_width", function (v) { return "width: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_height", function (v) { return "height: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_maxW", function (v) { return "max-width: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_minW", function (v) { return "min-width: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_maxH", function (v) { return "max-height: ".concat((0, formatters_1.px)(v)); }), (0, breakpointProp_1.breakpointProp)("_minH", function (v) { return "min-height: ".concat((0, formatters_1.px)(v)); }));
exports.default = exports.dimensionMixin;
var templateObject_1;
