"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textPropsType = void 0;
var textColor_1 = require("../../mixins/textColor");
var Box_1 = require("../Box");
exports.textPropsType = Box_1.boxPropsType
    .extend(textColor_1.textColorMixinPropsType)
    .complete({
    text: true,
    i18n: true,
    before: true,
    ellipsis: true,
    small: true,
    center: true,
    after: true,
    renderAs: true,
    breakWord: true,
    noWrap: true,
    strong: true,
    squished: true,
    htmlFor: true,
    href: true,
});
