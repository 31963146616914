"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWatchControllerValue = void 0;
var react_1 = require("react");
var useWatchControllerValue = function (controller) {
    var onChange = controller.onChange, controllerValue = controller.value;
    var _a = __read((0, react_1.useState)(controllerValue.current), 2), watchedValue = _a[0], setWatchedValue = _a[1];
    (0, react_1.useEffect)(function () {
        var unbindHandler = onChange(setWatchedValue);
        setWatchedValue(controllerValue.current);
        return unbindHandler;
    }, [onChange, setWatchedValue]);
    return watchedValue;
};
exports.useWatchControllerValue = useWatchControllerValue;
exports.default = exports.useWatchControllerValue;
