"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListValueController = void 0;
var useValueController_1 = require("../useValueController");
var strictEqualityMatcher_1 = require("./matcher/strictEqualityMatcher");
var useListValueController = function (initialValues, options) {
    if (initialValues === void 0) { initialValues = []; }
    if (options === void 0) { options = {}; }
    var _a = options.matcher, matcher = _a === void 0 ? strictEqualityMatcher_1.strictEqualityMatcher : _a;
    var singleValueController = (0, useValueController_1.useValueController)(initialValues);
    var onAddHandlers = new Set();
    var onRemoveHandlers = new Set();
    var notifyOnAddHandlers = function (value, event) {
        onAddHandlers.forEach(function (onAdd) { return onAdd(value, event); });
    };
    var notifyOnRemoveHandlers = function (value, event) {
        onRemoveHandlers.forEach(function (onRemove) { return onRemove(value, event); });
    };
    var controller = __assign(__assign({}, singleValueController), { add: function (value, event) {
            var updatedList = __spreadArray(__spreadArray([], __read(singleValueController.value.current), false), [value], false);
            if (singleValueController.updateValue(updatedList, event)) {
                notifyOnAddHandlers(value, event);
            }
        }, remove: function (valueToRemove, event) {
            var currentValues = singleValueController.value.current;
            var listWithRemovedValue = currentValues.filter(function (existingValue) {
                var valuesMatching = matcher(existingValue, valueToRemove);
                if (valuesMatching) {
                    notifyOnRemoveHandlers(existingValue, event);
                }
                return !valuesMatching;
            });
            var removedCount = currentValues.length - listWithRemovedValue.length;
            if (removedCount > 0) {
                singleValueController.updateValue(listWithRemovedValue, event);
            }
            return removedCount;
        }, onAdd: function (handler) {
            onAddHandlers.add(handler);
            return function () {
                onAddHandlers.delete(handler);
            };
        }, onRemove: function (handler) {
            onRemoveHandlers.add(handler);
            return function () {
                onRemoveHandlers.delete(handler);
            };
        }, exists: function (value) {
            return controller.value.current.some(function (existingValue) {
                return matcher(existingValue, value);
            });
        } });
    return controller;
};
exports.useListValueController = useListValueController;
exports.default = exports.useListValueController;
