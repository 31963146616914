"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isError = void 0;
function isError(something) {
    return (typeof something === "object" &&
        !!something &&
        "name" in something &&
        "message" in something);
}
exports.isError = isError;
exports.default = isError;
