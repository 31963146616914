"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boxPropsType = exports.placeholderBoxPropsType = void 0;
var Props_1 = require("../../lib/Props");
var box_1 = require("../../mixins/box");
exports.placeholderBoxPropsType = new Props_1.PropsType({
    loadingView: true,
    skeleton: true,
});
exports.boxPropsType = box_1.boxMixinPropsType
    .extend(exports.placeholderBoxPropsType)
    .complete({
    role: true,
    as: true,
    breakpointClassName: true,
    classNames: true,
    key: true,
    ref: true,
    style: true,
    className: true,
    onClick: true,
    id: true,
    onMouseDown: true,
    onMouseLeave: true,
    onMouseEnter: true,
    tabIndex: true,
    children: true,
});
