"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.snackbarsStore = void 0;
var mobx_state_tree_1 = require("mobx-state-tree");
var snackbarsStoreModel = mobx_state_tree_1.types
    .model({
    snackbars: mobx_state_tree_1.types.array(mobx_state_tree_1.types.maybeNull(mobx_state_tree_1.types.model({
        element: mobx_state_tree_1.types.frozen(),
        visible: mobx_state_tree_1.types.boolean,
    }))),
})
    .actions(function (self) {
    var actions = {
        hideSnackbar: function (index) {
            var snackbar = self.snackbars[index];
            if (snackbar) {
                snackbar.visible = false;
            }
        },
        addSnackbar: function (element, options) {
            if (options === void 0) { options = {}; }
            var index = self.snackbars.length;
            var _a = options.hideTimeout, hideTimeout = _a === void 0 ? 5000 : _a;
            self.snackbars.push({
                element: element,
                visible: true,
            });
            setTimeout(function () {
                actions.hideSnackbar(index);
            }, hideTimeout);
            setTimeout(function () {
                actions.deleteSnackbar(index);
            }, hideTimeout + 1000);
        },
        deleteSnackbar: function (index) {
            if (self.snackbars.length <= index) {
                return;
            }
            self.snackbars[index] = null;
        },
        clearAll: function () {
            self.snackbars.clear();
        },
    };
    return actions;
})
    .views(function (self) { return ({
    getActiveSnackbars: function () {
        return self.snackbars.filter(function (s) { return s !== null; });
    },
}); });
/** Global store that handles snackbar instances */
exports.snackbarsStore = snackbarsStoreModel.create();
exports.default = exports.snackbarsStore;
