"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePathWithLinkId = void 0;
var usePageTreeContext_1 = require("./usePageTreeContext");
var react_router_dom_1 = require("react-router-dom");
var usePathParams_1 = require("./usePathParams");
function usePathWithLinkId(linkId) {
    var path = (0, usePageTreeContext_1.usePageTreeContext)().pageTree.getPath(linkId);
    var pathParams = (0, usePathParams_1.usePathParams)();
    return (0, react_router_dom_1.generatePath)(path, pathParams);
}
exports.usePathWithLinkId = usePathWithLinkId;
