"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.offCanvasBoxShadow = exports.elevatedContentBoxShadow = exports.contentBoxShadow = exports.elevatedOverlayBoxShadow = exports.overlayBoxShadow = void 0;
var styled_components_1 = require("styled-components");
var variables_1 = require("../tokens/ts/variables");
exports.overlayBoxShadow = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"], ["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"])), variables_1.spacing.boxShadow.overlay.default.x, variables_1.spacing.boxShadow.overlay.default.y, variables_1.size.boxShadow.overlay.default, variables_1.opacity.boxShadow.overlay.default);
exports.elevatedOverlayBoxShadow = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"], ["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"])), variables_1.spacing.boxShadow.overlay.elevated.x, variables_1.spacing.boxShadow.overlay.elevated.y, variables_1.size.boxShadow.overlay.elevated, variables_1.opacity.boxShadow.overlay.elevated);
exports.contentBoxShadow = (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"], ["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"])), variables_1.spacing.boxShadow.content.default.x, variables_1.spacing.boxShadow.content.default.y, variables_1.size.boxShadow.content.default, variables_1.opacity.boxShadow.content.default);
exports.elevatedContentBoxShadow = (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"], ["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"])), variables_1.spacing.boxShadow.content.elevated.x, variables_1.spacing.boxShadow.content.elevated.y, variables_1.size.boxShadow.content.elevated, variables_1.opacity.boxShadow.content.default);
exports.offCanvasBoxShadow = (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"], ["\n  box-shadow: ", "\n    ", " ", "\n    rgba(0, 0, 0, ", ");\n"])), variables_1.spacing.boxShadow.offCanvas.default.x, variables_1.spacing.boxShadow.offCanvas.default.y, variables_1.size.boxShadow.offCanvas.default, variables_1.opacity.boxShadow.offCanvas.default);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
