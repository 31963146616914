"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyFormFieldValue = exports.formFieldPropsType = void 0;
var Props_1 = require("../Props");
exports.formFieldPropsType = new Props_1.PropsType({
    disabled: true,
    controller: true,
    value: true,
    name: true,
    onChange: true,
    hasError: true,
    inputRef: true,
    label: true,
    isOptional: true,
    errorMessage: true,
    infoMessage: true,
});
exports.emptyFormFieldValue = null;
