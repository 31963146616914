"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFieldErrorParams = exports.updateFieldErrorParams = void 0;
var deepMerge_1 = require("@mittwald/flow-lib/dist/object/deepMerge");
var fast_equals_1 = require("fast-equals");
var react_1 = require("react");
// see https://react-hook-form.com/get-started#Applyvalidation
var reactHooksFormValidationRules = [
    "min",
    "max",
    "minLength",
    "maxLength",
    "pattern",
];
var updateFieldErrorParams = function (existingParams, path, newParam) {
    var _a;
    var mergeInObject = (_a = {},
        _a[path] = newParam,
        _a);
    if (!existingParams) {
        return mergeInObject;
    }
    var merged = (0, deepMerge_1.deepMerge)(existingParams, mergeInObject);
    if ((0, fast_equals_1.deepEqual)(merged, existingParams)) {
        return existingParams;
    }
    return merged;
};
exports.updateFieldErrorParams = updateFieldErrorParams;
/**
 * This hooks holds params for field errors, i.e. to be used in translations of
 * validation messages.
 */
var useFieldErrorParams = function () {
    var _a = __read((0, react_1.useState)(), 2), params = _a[0], setParams = _a[1];
    var controller = {
        params: params,
        update: function (path, params) {
            setParams(function (existingParams) {
                return (0, exports.updateFieldErrorParams)(existingParams, path, params);
            });
        },
        mergeInFields: function (fields) {
            var e_1, _a, e_2, _b, _c;
            try {
                for (var _d = __values(Object.entries(fields)), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var _g = __read(_e.value, 2), fieldName = _g[0], field = _g[1];
                    // _f is required in Field, but nested objects in form default values
                    // are passed to the fields ref without any checks
                    if (!field || !("_f" in field)) {
                        continue;
                    }
                    try {
                        for (var reactHooksFormValidationRules_1 = (e_2 = void 0, __values(reactHooksFormValidationRules)), reactHooksFormValidationRules_1_1 = reactHooksFormValidationRules_1.next(); !reactHooksFormValidationRules_1_1.done; reactHooksFormValidationRules_1_1 = reactHooksFormValidationRules_1.next()) {
                            var rule = reactHooksFormValidationRules_1_1.value;
                            var ruleValue = field._f[rule];
                            if (typeof ruleValue !== "object") {
                                controller.update(fieldName, (_c = {}, _c[rule] = ruleValue, _c));
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (reactHooksFormValidationRules_1_1 && !reactHooksFormValidationRules_1_1.done && (_b = reactHooksFormValidationRules_1.return)) _b.call(reactHooksFormValidationRules_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_1) throw e_1.error; }
            }
        },
    };
    return controller;
};
exports.useFieldErrorParams = useFieldErrorParams;
exports.default = exports.useFieldErrorParams;
