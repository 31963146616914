"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.paddingMixin = exports.paddingMixinPropsType = void 0;
var styled_components_1 = require("styled-components");
var Props_1 = require("../lib/Props");
var breakpointProp_1 = require("./breakpointProp");
var formatters_1 = require("./formatters");
exports.paddingMixinPropsType = new Props_1.PropsType({
    _p: true,
    _pb: true,
    _pl: true,
    _pr: true,
    _pt: true,
    _px: true,
    _py: true,
});
exports.paddingMixin = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), (0, breakpointProp_1.breakpointProp)("_p", function (v) { return "padding: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_px", function (v) { return "padding-left: ".concat((0, formatters_1.spacingPx)(v), "; padding-right: ").concat((0, formatters_1.spacingPx)(v), ";"); }), (0, breakpointProp_1.breakpointProp)("_py", function (v) { return "padding-top: ".concat((0, formatters_1.spacingPx)(v), "; padding-bottom: ").concat((0, formatters_1.spacingPx)(v), ";"); }), (0, breakpointProp_1.breakpointProp)("_pt", function (v) { return "padding-top: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_pb", function (v) { return "padding-bottom: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_pl", function (v) { return "padding-left: ".concat((0, formatters_1.spacingPx)(v)); }), (0, breakpointProp_1.breakpointProp)("_pr", function (v) { return "padding-right: ".concat((0, formatters_1.spacingPx)(v)); }));
var templateObject_1;
