"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOverlayVisibility = void 0;
var useVisibilityController_1 = require("@mittwald/flow-components/dist/hooks/useVisibilityController");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var pageMatcher_1 = require("../lib/pageMatcher");
/** Use this hook to get a URL based visibility controller for an overlay */
var useOverlayVisibility = function (breadcrumb) {
    var _a;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var modalBreadcrumb = breadcrumb.findParentBreadcrumb(pageMatcher_1.modal, true);
    var mainContentBreadcrumb = (_a = breadcrumb.findParentBreadcrumb(pageMatcher_1.noModal, true)) !== null && _a !== void 0 ? _a : breadcrumb;
    var navigateToMainContentPage = function () {
        navigate(mainContentBreadcrumb.buildPath());
    };
    var visibility = (0, useVisibilityController_1.useVisibilityController)(!!modalBreadcrumb, {
        onHide: navigateToMainContentPage,
    });
    (0, react_1.useEffect)(function () {
        visibility.setVisibility(!!modalBreadcrumb);
    }, [!modalBreadcrumb]);
    return visibility;
};
exports.useOverlayVisibility = useOverlayVisibility;
