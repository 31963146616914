"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorBoundaryContext = exports.ErrorBoundaryContextProvider = void 0;
var react_1 = require("react");
var errorBoundaryContext = (0, react_1.createContext)({
    reportingEnabled: true,
    showRawError: false,
});
var ErrorBoundaryContextProvider = function (props) {
    var children = props.children, context = __rest(props, ["children"]);
    return (0, react_1.createElement)(errorBoundaryContext.Provider, { value: context }, children);
};
exports.ErrorBoundaryContextProvider = ErrorBoundaryContextProvider;
var useErrorBoundaryContext = function () {
    return (0, react_1.useContext)(errorBoundaryContext);
};
exports.useErrorBoundaryContext = useErrorBoundaryContext;
exports.default = exports.ErrorBoundaryContextProvider;
